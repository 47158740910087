/*------------------------------------------------------------------
[16. Checkout]
*/
.shopping-cart-box{
	&:not(:last-child){
		margin-bottom: 5px;
	}
	&+.shopping-cart-box{
		border-top: 1px solid #e4e4e4;
	}
	.header-box-admin{
		@media (max-width: 991px) {
			padding-top: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.body-box-admin{
		@media (max-width: 991px) {
			padding-bottom: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}
.shopping-cart-info{
	display: flex;
	margin-left: -10px;
	margin-right: -10px;
	flex-wrap: wrap;
}
.package-item{
	padding-left: 10px;
	padding-right: 10px;
	min-height: 10px;
	position: relative;
	@include flexWidth(50%);
	a{
		padding: 15px;
		border: 1px solid #e4e4e4;
		display: block;
		border-radius: 5px;
		color: $clr-2;
		height: 100%;
		@include breakpoint(lg) {
			padding: 26px 30px 23px;
		}
		.name{
			margin-bottom: 10px;
			font-weight: 600;
			position: relative;
			padding-left: 22px;
			&::before{
				position: absolute;
				top: 3px;
				left: 0;
				display: inline-block;
				content: "\f111";
				font-size: 1em;
				font-family: 'Font Awesome 5 Free';
    			font-weight: 400;
				text-align: center;
				line-height: 1;
			}
		}
		.price{
			margin-bottom: 0;
		}
		&.active, &:hover{
			border-color: $bg-basic-1;
			.name{
				color: $clr-1;
				&::before{
					font-weight: 900;
					content: "\f058";
				}
			}
		}
	}
}
.payment-method{
	.choose-payment-method{
		margin-bottom: 25px;
		.payment-method-item{
			display: inline-block;
			margin-bottom: 5px;
			&:not(:last-child) {
				margin-right: 25px;
			}
		}
	}
	.payment-method-content{
		margin-bottom: -15px;
		.form-group{
			@include breakpoint(md){
				margin-bottom: 20px;
			}
		}	
	}
}
.affix-top{
	.header-box-admin{
		@media (max-width: 991px) {
			padding-top: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.body-box-admin{
		@media (max-width: 991px) {
			padding-bottom: 15px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.fee{
		display: flex;
		justify-content: space-between;
	  span{
		color: $clr-2;
		padding-right: 10px;
		flex: 1 1 0px;
		font-size: 1.125em;
	  }
		strong{
		font-weight: normal;
		color: $clr-basic-text;
		text-align: right;
		min-height: 1px;
	  }
	}
	.sub-total{
		border-top: 1px solid #e4e4e4;
		margin-top: 23px;
		padding-top: 25px;
		display: flex;
		justify-content: space-between;
	  .text-label{
		font-size: 1.125em;
		color: $clr-2;
		flex: 1 1 0px;
	  }
	  .amount{
		color: $clr-1;
		font-weight: 600;
		font-size: 1.125em;
	  }
	}
	.tick{
		margin-top: -7px;
		margin-bottom: 10px;
	  input{
		margin-right: 3px;
		position: relative;
		top: 2px;
	  }
	}
}
