/*------------------------------------------------------------------
[9. Job List]
*/
.content-main-right {
	background-color: $clr-white;
	@include border-radius(5px);
}

.header-list-job {
	border-bottom: 1px solid #e4e4e4;
  padding: 14px 20px 13px;
  @include breakpoint(md){
    padding-left: 30px;
    padding-right: 30px;
  }
	h4 {
		font-weight: 600;
		font-size: 1em;
		margin-bottom: 0;
	}
}
.dropdown-custom {
	span {
		color: $clr-2;
	}
	button {
		font-weight: 600;
	}
	.dropdown-menu {
		padding: 0;
	}
	.dropdown-item {
		font-size: 0.875em;
		padding: 6px 15px;
		&:hover, &:focus {
			background-color: transparent;
			color: $clr-1;
		}
	}
}

.job-item {
	border-bottom: 1px solid #e4e4e4;
	color: $clr-2;
	padding: 20px;
  position: relative;
  @include breakpoint(md){
    padding: 30px 30px 25px;
  }
  .col-md-2 {
    @include breakpoint(md) {
      @include flexWidth(115px);
    }
  }
  .col-md-10 {
    @include breakpoint(md) {
      @include flexWidth(calc(100% - 115px));
    }
  }
  @media (max-width: 767px){
    position: relative;
    .job-info{
      position: static;
    }
  }
	&:hover {
		background-color: #fcfcfc;
		.title-job {
			color: $clr-1;
			a {
				color: $clr-1;
			}
		}
    .company-name {
      color: $clr-basic-text;
    }
		.add-favourites {
			background-color: $clr-3;
			color: $clr-white;
			i {
				&:before {
					content: "\f005";
				}
			}
		}
  }
  .text{
    @include breakpoint(md){
      position: relative;
      padding-right: 52px;
    }
  }
  .add-favourites{
    position: absolute;
    top: 15px;
    right: 15px;
    @include breakpoint(md){
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
.title-job {
	font-size: 1.125em;
	color: $bg-basic-2;
	font-weight: 600;
	margin-bottom: 8px;
	a {
		color: $bg-basic-2;
		&:hover, &:focus {
			color: $clr-1;
		}
	}
}
.date-job {
	padding-bottom: 3px;
	.fa-check-circle {
		color: $clr-1;
		margin-right: 4px;
	}
}
.meta-job {
  color: $clr-2;
	span {
		display: inline-block;
		margin-top: 3px;
		&:not(:last-child) {
      margin-right: 15px;
      @include breakpoint(md){
        margin-right: 24px;
      }
		}
		i {
			margin-right: 6px;
		}
	}
}

.add-favourites {
	border: none;
	background-color: #f1f1f1;
	@include square(44px);
	text-align: center;
	line-height: 44px;
	color: $clr-2;
	@include border-radius(5px);
	&:hover, &:focus {
		background-color: $clr-3;
		color: $clr-white;
		i {
			&:before {
				content: "\f005";
			}
		}
	}
}

/*----------- job list map -----------*/
.content-job-list-map {
  position: relative;
  @include breakpoint(xl) {
    height: calc(100vh - 142px);
    overflow-y: auto;
    @include dFlex();
  }
  .container {
    @include breakpoint(xl) {
      margin-left: 0;
      margin-right: 0;
      max-width: calc(100% - 450px);
    }
    @media (min-width: 1920px) {
      max-width: 1140px;
    }
  }
	.map-small {
		margin-bottom: 30px;
		height: 450px;
		overflow: hidden;
		@include breakpoint(xl) {
			display: none;
		}
	}
  .map {
    margin-bottom: 30px;
    overflow: hidden;
    max-height: 350px;
    @include breakpoint(lg) {
      max-height: 550px;
    }
    @include breakpoint(xl) {
      margin-bottom: 0;
      max-height: unset;
      min-width: 450px;
      width: calc(100% - 1155px);
      background-color: #ebebeb;
      // margin-top: -45px;
      position: fixed;
      display: block;
      top: 100px;
      right: 0;
      bottom: 0;
      // padding-left: 40px;
      margin-bottom: 0;
      &:after {
        top: 100%;
        left: 0;
        right: 0;
        height: 500px;
        background-color: #ebebeb;
        position: absolute;
        content: "";
      }
      .container {
        padding: 0;
      }
      img {
        height: 100%;
      }
    }
    @media (min-width: 1920px) {
      min-width: unset;
    }
  }
}
.browse-job-list-map {
	.wrapper {
		overflow: hidden;
	}
	.section-heading {
		@media (min-width: 1920px) {
			display: none;
		}
	}
	&.collapse-filter {
    @media (min-width: 1920px) {
		  .side-left-toggle {			
				display: none;
      }
      .side-right-toggle{
        @include flexWidth(100%);
      }
      .content-job-list-map .map {
        @include flexWidth(calc(100% - 790px));
        margin-left: -350px;
      }
		}
	}
}
.hide-filter {
	font-size: 1.125em;
	font-weight: 600;
	padding-left: 60px;
	margin-bottom: 20px;
	display: none;
	@media (min-width: 1920px) {
		display: block;
	}
	.custom-control-label {
		&:before {
			height: 14px;
			width: 44px;
			background-color: #e6e6e6;
			left: -60px;
			border: none;
			top: 6px;
			@include box-shadow(none);
		}
		&:after {
			@include square(22px);
			border: 1px solid $clr-white;
			background-color: $clr-1;
			@include border-radius(50%);
			top: 2px;
		}
	}
	.custom-control-input:checked~.custom-control-label {
		&:before {
			background-color: #e6e6e6;
			@include box-shadow(none);
		}
		&:after {
			border: 1px solid $clr-white;
			background-color: $clr-1;
			right: 0;
			left: -60px;
			transform: inherit;
		}
	}
}