/*------------------------------------------------------------------
[12. Candidate]
*/
.candidate-item{
  @include flexbox();
  flex-wrap: wrap;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  padding: 30px 0;
  color: $clr-2;
  &.hover{
    @include breakpoint(lg){
      &:hover {
        .title-job {
          color: $clr-1;
          a {
            color: $clr-1;
          }
        }
        .btn-light {
          background-color: $clr-1;
          border-color: $clr-1;
          color: $clr-white;
        }
      }
    }
  }
}
.candidate-img{
  @include flexWidth(115px);
  @include breakpoint(xl){
    @include flexWidth(145px);
  }
  img{
    border-radius: 50%;
    width: 85px;
    height: 85px;
    display: block;
    margin: 0 auto;
  }
}
.candidate-content{
  @include flexWidth(calc(100% - 115px));
  @include breakpoint(xl){
    @include flexWidth(calc(100% - 145px));
  }
  .candidate-button{
    padding-right: 15px;
    text-align: right;
    @media (max-width: 767px){
      margin-top: 7px;
      text-align: left;
    }
    .btn-add-favourites{
      background-color: #ffb13c;
      color: $clr-white;
    }
  }
}
.item-list{
  display: flex;
  flex-wrap: wrap;
  .item-img{
    @include flexWidth(50px);
    img{
      width: 50px;
      height: 50px;
      display: block;
      border-radius: 5px;
    }
  }
  .item-body{
    @include flexWidth(calc(100% - 50px));
    padding-left: 20px;
    @include breakpoint(md){
      padding-left: 30px;
    }
  }
  .item-title{
    font-weight: 600;
    color: #383838;
    .item-position{
      color: $clr-1;
    }
  }
  &:not(:last-child){
    margin-bottom: 25px;
  }
}
.process{
  margin-bottom: 25px;
  .process-point{
    font-weight: 600;
    margin-bottom: 6px;
    color: #383838;
  }
  .process-in-category{
    color: $clr-2;
  }
  .process-bar{
    height: 4px;
    background-color: #e6e6e6;
    border-radius: 2px;
    position: relative;
    .process-bar-status{
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $bg-basic-1;
      border-radius: 2px;
      display: inline-block;
    }
  }
}