/*------------------------------------------------------------------
[7. Right column]
*/
.sidebar-right{
  @media (max-width: 991px) {
    margin-top: 16px;
  }
  .search-form{
    margin-bottom: 30px;
  }
  .sidebar-right-group{
    background-color: $clr-white;
    border-radius: 5px;
  }
}
.list-news-thumb{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li{
    overflow: hidden;
    &:not(:last-child){
      margin-bottom: 15px;
      @include breakpoint(md){
        margin-bottom: 25px;
      }
    }
    .thumb{
      float: left;
      margin-right: 15px;
      width: 67px;
      img{
        display: block;
        border-radius: 5px;
      }
    }
    .content{
      width: calc(100% - 67px - 15px);
      float: left;
    }
    .meta{
      color: $clr-2;
      line-height: 18px;
    }
  }
}
.list-news-title{
  margin-top: -5px;
  margin-bottom: 8px;
  font-size: 1em;
  line-height: 1.4;
  font-weight: 600;
  a{
    color: inherit;
    &:hover{
      color: $clr-1;
    }
  }
}
.tags{
  a{
    display: inline-block;
    background-color: #f1f1f1;
    border-radius: 3px;
    transition: all .3s;
    margin: 0 5px 5px 0;
    padding: 4px 10px;
    color: $clr-2;
    font-weight: 600;
    &:hover{
      background-color: $bg-basic-1;
      color: $clr-white;
    }
  }
}

.job-detail-summary {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
  @include breakpoint(md){
    padding: 30px;
  }
  h3 {
    margin-bottom: 20px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      color: $clr-2;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      span {
        color: $clr-basic-text;
        font-weight: 600;
      }
    }
  }
  .btn{
    margin-top: 36px;
  }
}

.side-right-map {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
  @include breakpoint(md){
    padding: 30px;
  }
  #map_canvas {
    height: 300px;
  }
}
#map-right {
  @include border-radius(5px);
}

.side-right-social {
  padding: 30px 30px 20px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include dFlex();
    @include flexWrap();
    li {
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
        display: inline-block;
        background-color: #f1f1f1;
        @include square(44px);
        text-align: center;
        line-height: 44px;
        color: $clr-2;
        @include border-radius(4px);
        i {
          vertical-align: middle;
        }
        &:hover, &:focus {
          background-color: $clr-1;
          color: $clr-white;
        }
      }
    }
  }
}