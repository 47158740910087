//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `Extra small` as of 4.0
$screen-xs: 575px;

//** Deprecated `Small` as of 4.0
$screen-sm: 576px;

//** Deprecated `Medium` as of 4.0
$screen-md: 768px;

//** Deprecated `Large` as of 4.0
$screen-lg:992px;

//** Deprecated `Extra large` as of 4.0
$screen-xl: 1200px;

$clr-3: #ffb13c;