/*------------------------------------------------------------------
[19. Footer]
*/
.footer-site {
	background-color: $bg-basic-2;
	color: $clr-2;
	margin-top: 50px;
}
.footer-top {
	border-bottom: 1px solid rgba($clr-white,0.2);
	padding: 50px 0;
	color: $clr-white;
	margin-bottom: 46px;
}
.nav-footer {
	margin: 0;
	padding: 0;
	list-style: none;
	@include breakpoint(lg) {
		text-align: right;
	}
	li {
		display: inline-block;
		&:not(:last-child) {
			margin-right: 28px;
		}
		a {
			color: $clr-white;
			&:hover {
				color: $clr-1;
			}
		}
	}
}
.footer-middle {
	color: $clr-2;
	border-bottom: 1px solid rgba($clr-white,0.2);
	padding-bottom: 20px;
	a {
		color: $clr-2;
		&:hover {
			color: $clr-1;
		}
	}
}
.title-footer {
	font-size: 1.125em;
	font-weight: 600;
	margin-bottom: 16px;
	color: $clr-white;
}
.newsletter {
	margin-bottom: 30px;
	.name {
		color: $clr-white;
		span {
			color: $clr-1;
		}
	}
	p {
		margin-bottom: 24px;
	}
	.form {
		position: relative;
		padding-right: 54px;
		.form-control {
			background-color: #3f3f3f;
			border-color: #3f3f3f;
			color: $clr-2;
			height: 44px;
			padding-left: 20px;
			&:focus{
				box-shadow: none;
				border-color: $bg-basic-1;
			}
		}
		button {
			position: absolute;
			top: 0;
			right: 0;
			width: 44px;
			height: 44px;
			background-color: $clr-1;
			color: $clr-white;
			font-size: 16px;
			text-align: center;
			border: none;
			cursor: pointer;
			@include border-radius(4px);
			&:hover, &:focus {
				color: $clr-white;
				background-color: darken($clr-1, 5%);
			}
		}
	}
}
.social-footer {
	list-style: none;
	padding: 0;
	margin: 0 0 25px;
	li {
		display: inline-block;
		&:not(:last-child) {
			margin-right: 14px;
		}
		a {
			color: $clr-2;
			&:hover, &:focus {
				color: $clr-1;
			}
		}
	}
}
.links-footer {
	list-style: none;
	padding: 0;
	margin: 0 0 25px;
	li {
		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
}
.footer-bottom {
	padding: 44px 0 48px;
}
.language-footer {
	button {
		color: $clr-white;
		margin-left: 3px;
	}
	.dropdown-menu {
		padding: 0;
		@include border-radius(0);
		.dropdown-item {
			padding: 6px 15px;
			font-size: 14px;
			&:hover, &:focus {
				background-color: $clr-1;
				color: $clr-white;
			}
		}
	}
}
.footer-site-style2 {
	background-color: transparent;
	color: $clr-2;
	.text-white {
		color: $clr-basic-text !important;
		font-weight: 700;
	}
	.language-footer {
		button {
			color: $clr-basic-text;
		}
	}
	.container{
		padding-left: 15px;
		padding-right: 15px;
		max-width: 100%;
	}
}
.browse-job-list-map {
	.footer-site-style2 {
		.container {
			@media (min-width: 1920px) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
