/*------------------------------------------------------------------
[6. Left column]
*/
.sidebar-left {
	background-color: $clr-white;
	@include border-radius(5px);
	@media (max-width: 911px){
		position: fixed;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		z-index: 999;
		@include border-radius(0);
		overflow-x: hidden;
		overflow-y: auto;
		@include transition-value();
		&.show {
			left: 0;
		}
	}
}
.btn-close-sidebar-left {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1.125em;
	color: $clr-1;
	padding: 5px;
	@include breakpoint(lg) {
		display: none;
	}
}
.toggle-sidebar-left {
	background-color: $clr-1;
	@include border-radius(5px);
	margin-bottom: 20px;
	padding: 8px 30px;
	cursor: pointer;
	font-size: 1.125em;
	font-weight: 600;
	text-align: center;
	color: $clr-white;
}
.title-box {
	color: $bg-basic-2;
	font-size: 1.125em;
	font-weight: 600;
	margin-bottom: 0;
	span{
		color: $clr-1;
	}
}
.box-sidebar {
	padding: 27px 30px 17px;
	border-bottom: 1px solid #e4e4e4;
	&:last-child {
		border-bottom: none;
	}
}
.header-box {
	position: relative;
	margin-bottom: 19px;
	.search {
		.btn-toggle-search {
			color: $clr-1;
		}
		.search-form {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			background-color: $clr-1;
			height: 40px;
			padding: 2px;
			z-index: 2;
			display: none;
			@include border-radius(4px);
			.form-control {
				height: 36px;
			}
			button {
				height: 40px;
			}
			&.show {
				display: block;
			}
		}
	}
}
.list-check-filter-job {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}
	label {
		color: $clr-2;
	}
}
.custom-checkbox .custom-control-input:checked~.custom-control-label {
	color: $bg-basic-2;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
	background-color: $clr-1;
	border-color: $clr-1;
}
.scroller {
	max-height: 300px;
	.mCSB_container_wrapper{
		margin-right: 10px;
	}
	.mCSB_scrollTools{
		width: 4px;
	}
	.mCS-3d.mCSB_scrollTools .mCSB_draggerRail{
		box-shadow: none;
		background-color: #e6e6e6;
		border-radius: 5px;
	}
	.mCSB_container_wrapper > .mCSB_container{
		padding-right: 0;
	}
	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
		background-color: $clr-1;
	}
	.mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
	.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		width: 4px;
	}
}

.salary-range {
	#amount {
		border: none;
		font-size: 1em;
		color: $clr-2;
		font-weight: 500;
		padding: 0;
		margin-bottom: 14px;
		&:focus {
			outline: none;
		}
	}

	.ui-widget.ui-widget-content{
		border: none;
		background-color: #dddddd;
		@include border-radius(2px);
		height: 4px;
	}
	.ui-slider-horizontal{
		.ui-slider-range{
			background-color:$clr-1;
		}
	}
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
		@include border-radius(50%);
		@include square(16px);
		border: 1px solid $clr-white;
		background-color: $clr-1;
		@include box-shadow(0 0 10px rgba($clr-1,0.6));
		top: -7px;
	}
}

.sidebar-admin {
	background-color: $clr-white;
	margin-top: -42px;
	padding: 30px 0;
	@media (max-width: 1023px) {
		position: fixed;
		top: 0;
		left: -320px;
		z-index: 99;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: auto;
		@include box-shadow(0 3px 7px rgba($clr-black,0.3));
		@include transition-value();
		width: 310px;
		margin-top: 0;
		&.show {
			left: 0;
		}
	}
}
.toggle-sidebar-admin {
	background-color: $clr-1;
	@include square(42px);
	text-align: center;
	line-height: 42px;
	color: $clr-white;
	position: fixed;
	top: 60px;
	left: 0;
	z-index: 99;
	display: inline-block;
	border: none;
	font-size: 1.25em;
	@media (min-width: 1024px) {
		display: none;
	}
	i {
		@include transition-value();
	}
	&.active {
		left: 310px;
		i {
			@include transform(rotate(180deg));
		}
	}
}
.header-user {
	@include dFlex();
	@include flexWrap();
	@include alignItems(center);
	color: $clr-2;
	padding: 0 30px 30px;
	.avatar {
		@include flexWidth(60px);
		img {
			max-height: 60px;
			@include border-radius(50%);
		}
	}

	.info-user {
		@include flexWidth(calc(100% - 60px));
		padding-left: 20px;
		h3 {
			font-weight: 600;
			font-size: 1.125em;
			color: $clr-basic-text;
			margin-bottom: 0;
			a {
				color: $clr-basic-text;
				&:hover {
					color: $clr-1;
				}
			}
		}
		p {
			margin-bottom: 0;
		}
	}
}
.nav-sidebar-admin {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		a {
			color: $clr-2;
			display: block;
			padding: 10px 30px;
			&:hover, &:focus {
				color: $clr-1;
				background-color: #fcfcfc;
			}
			i {
				margin-right: 8px;
			}
		}
		&.active {
			a {
				color: $clr-1;
				background-color: #fcfcfc;
			}
		}
		.badge {
			margin-left: 8px;
		}
	}
}
.badge {
	font-weight: 600;
	font-size: 0.75em;
	@include border-radius(50%);
}
.badge-green {
	background-color: $clr-1;
	color: $clr-white;
}
.list-categories{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	li {
		color: $clr-2;
		&:not(:last-child){
			margin-bottom: 4px;
		}
		&.active{
			a{
				color: $clr-1;
			}
		}
		a{
			color: inherit;
			&:hover{
				color: $clr-1;
			}
		}
	}
}