/*------------------------------------------------------------------
[5. breadcrumb]
*/
.breadcrumb {
	background-color: transparent;
	padding: 0;
}
.breadcrumb-item {
	&+.breadcrumb-item {
		&:before {
			content: "-";
		}
	}
	a {
		color: $clr-2;
		&:hover, &:focus {
			color: $clr-1;
		}
	}
	&.active {
		color: $clr-1;
	}
}
