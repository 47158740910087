/*------------------------------------------------------------------
[Table of contents]

1. Body
2. Header / #header
3. Navigation / #navigation
4. Content / #content
5. Breadcrumd / breadcrumb
6. Left column / #leftcolumn
7. Right column / #rightcolumn
8. Pagination / .pagination-page
9. Job List / .list-jobs
10. Employer
11. Admin
    11.1 Dashboard
    11.2 Profile
    11.3 Manage Jobs
    11.4 Manage candidates
    11.5 Employer Message
    11.6 Employer Transactions
12. Candidate
13. Blog
14. Form
15. FAQ
16. Checkout
17. Error
18. Contact
19. Footer / #footer
-------------------------------------------------------------------*/

@import 'mixins';
@import 'mixins2';
@import 'variables';
@import 'variables2';
@import 'contents/general';
@import 'contents/header';
@import 'contents/navigation';
@import 'contents/home';
@import 'contents/breadcrumd';
@import 'contents/left-side';
@import 'contents/right-side';
@import 'contents/pagination';
@import 'contents/jobslist';
@import 'contents/employer';
@import 'contents/admin';
@import 'contents/candidate';
@import 'contents/blog';
@import 'contents/form';
@import 'contents/faq';
@import 'contents/checkout';
@import 'contents/error';
@import 'contents/contact';
@import 'contents/footer';