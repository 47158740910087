/*------------------------------------------------------------------
[11. Dashboard]
*/

/* 11.1 Dashboard */
.wrapper-admin {
  @include dFlex();
  @include flexWrap();
}
.sidebar-admin {
  @include flexWidth(310px);
}
.main-content-admin {
  @include flexWidth(100%);
  padding: 0 15px;
  @media (min-width: 1024px) {
    @include flexWidth(calc(100% - 310px));
    padding: 0 25px;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .footer-site {
    margin-top: 0;
  }
  .footer-bottom {
    padding-top: 14px;
  }
}
.section-statics-dashboard {
  .col-item {
    margin-bottom: 30px;
  }
}
.static-dashboard-item {
  background-color: $clr-white;
  @include dFlex();
  @include flexWrap();
  @include alignItems(center);
  padding: 16px;
  @include border-radius(5px);
  min-height: 100%;
  @include breakpoint(lg){
    padding: 27px 30px 20px;
  }
  .static-icon {
    @include flexWidth(70px);
    margin-top: -4px;
    i{
      font-size: 3.875em;
      line-height: 1;
      color: $clr-1;
    }
  }
  .text {
    @include flexWidth(calc(100% - 70px));
    padding-left: 12px;
    h3 {
      color: $clr-2;
      font-size: 1.125em;
      font-weight: normal;
      margin-bottom: 2px;
      margin-top: 4px;
    }
    .number {
      font-weight: 600;
      font-size: 1.875em;
    }
  }
}

.box-admin {
  background-color: $clr-white;
  @include border-radius(5px);
  margin-bottom: 30px;
  @include breakpoint(md){
    margin-bottom: 30px;
  }
}
.header-box-admin {
  @include dFlex();
  @include flexWrap();
  @include justifyContent(space-between);
  @include alignItems(center);
  margin-bottom: 21px;
  padding: 15px 15px 0;
  @include breakpoint(md) {
    padding: 25px  30px 0;
  }
  h3 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.125em;
  }
  .dropdown-custom {
    button {
      color: $clr-2;
    }
  }
  .section-notification-message & {
    padding-top: 16px;
    padding-left: 27px;
    margin-bottom: 16px;
    h3{
      font-size: 1em;
    }
  }
}
.body-box-admin {
  padding: 0 15px 15px;
  @include breakpoint(md) {
    padding: 0 30px 25px;
  }
}
.chart{
  padding-bottom: 8px;
}
.avatar-user {
  position: relative;
  @include square(60px);
  a {
    display: block;
  }
  img {
    @include border-radius(50%);
    @include square(60px);
  }
  .status {
    display: inline-block;
    @include border-radius(50%);
    @include square(14px);
    border: 1px solid $clr-white;
    position: absolute;
    bottom: 0;
    right: 0;
    &.online {
      background-color: $clr-1;
    }
    &.alway {
      background-color: #ffb13c;
    }
  }
}

.list-content-user {
  padding: 0;
  list-style: none;
  margin: 0;
  li {
    @include dFlex();
    @include flexWrap();
    @include alignItems(center);
    padding: 10px 15px;
    color: $clr-2;
    border-top: 1px solid #e4e4e4;
    @include breakpoint(md) {
      padding: 20px 30px;
    }
    &:hover {
      background-color: #fcfcfc;
    }
    .thumb-avatar {
      @include flexWidth(60px);
    }
    .text {
      @include flexWidth(calc(100% - 60px));
      padding-left: 20px;
      @include breakpoint(md) {
        @include dFlex();
        @include flexWrap();
        @include alignItems(center);
        .text-left {
          padding-right: 20px;
          @include flexWidth(calc(100% - 130px));
        }
        .time {
          @include flexWidth(130px);
          text-align: right;
        }
      }
    }
    h3 {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0;
      a {
        color: $clr-basic-text;
        &:hover {
          color: $clr-1;
        }
      }
    }
  }
}

/* 11.1 Profile */
.upload-avatar {
  @include dFlex();
  @include flexWrap();
  margin-bottom: 25px;
  .avatar {
    @include flexWidth(80px);
    @include border-radius(5px);
    @include breakpoint(sm) {
      @include flexWidth(100px);
    }
    img {
      @include border-radius(5px);
    }
  }
  .upload {
    @include flexWidth(calc(100% - 80px));
    padding-left: 15px;
    @include breakpoint(sm) {
      @include flexWidth(calc(100% - 100px));
      padding-left: 30px;
    }
    .desc {
      color: $clr-2;
      margin-bottom: 15px;
    }
    .btn-upload {
      display: inline-block;
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.mb-30 {
  margin-bottom: 30px;
}
.edit-profile {
  .header-box-admin {
    margin-bottom: 17px;
  }
  .upload-avatar {
    padding-top: 9px;
  }
  label {
    margin-bottom: 6px;
    font-weight: 600;
    @include breakpoint(md){
      margin-bottom: 16px;
    }
  }
  .form-group {
    margin-bottom: 24px;
  }
  .custom-control-label {
    margin-bottom: 0;
  }
  .form-control {
    @include breakpoint(md){
      padding-left: 20px;
    }
  }
}

/* 11.3 Manage Jobs */
.tbl-job {
  margin-bottom: 0;
  thead {
    th {
      border-bottom-width: 1px;
      border-top: none;
      font-weight: 600;
      padding: 0 15px 15px;
      &:first-child {
        width: 60%;
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
    }
  }
  tbody {
    td {
      white-space: nowrap;
      vertical-align: middle;
      padding: 26px 15px;
      &:first-child {
        white-space: normal;
        @include breakpoint(md){
          padding-left: 30px;
        }
      }
      &:last-child {
        @include breakpoint(md){
          padding-right: 30px;
        }
      }
    }
  }
}
.salary-job {
  color: $clr-2;
  i {
    margin-right: 6px;
  }
}
.my-job-item {
  color: $clr-2;
  .title-job {
    font-weight: normal;
    margin-bottom: 5px;
  }
  .meta-job {
    margin-bottom: 6px;
  }
  .btn {
    padding: 0;
    @include square(34px);
    text-align: center;
    line-height: 34px;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  .active {
    color: $clr-1;
  }
  .job-info {
    margin-bottom: 6px;
    span {
      display: inline-block;
      &:not(:last-child) {
        &:after {
          content: "/";
          margin: 0 10px;
        }
      }
    }
  }
  &:hover {
    background-color: #fcfcfc;
    .title-job {
      color: $clr-1;
      a {
        color: $clr-1;
      }
    }
    .number-application {
      color: $clr-1;
    }
    .btn {
      color: $clr-white;
    }
    .btn-view, .btn-new {
      background-color: $clr-3;
      border-color: $clr-3;
    }
    .btn-edit {
      background-color: $clr-1;
      border-color: $clr-1;
    }
    .btn-delete {
      background-color: $clr-4;
      border-color: $clr-4;
    }
  }
}

/* 11.4 Manage candidates */
.candidate-tile {
  @include dFlex();
  @include flexWrap();
  @include alignItems(center);
  .img {
    @include flexWidth(85px);
    img {
      @include border-radius(50%);
    }
  }
  .text {
    @include flexWidth(calc(100% - 85px));
    padding-left: 15px;
    @include breakpoint(sm){
      padding-left: 30px;
    }
  }
}
.rating-job {
  color: $clr-3;
  i {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.my-job-item.candidate {
  .title-job {
    margin-bottom: 8px;
  }
  .date-job {
    padding-bottom: 7px;
  }
}
.job-info-mobile {
  margin-top: 15px;
  @include breakpoint(md) {
    padding-left: 115px;
    margin-top: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 15px;
    @include breakpoint(md) {
      margin-bottom: 0;
    }
    li {
      strong {
        font-weight: 600;
      }
    }
  }
}
.job-item.bookmark {
  &:last-child {
    border-bottom: none;
  }
  .text {
    @include breakpoint(md) {
      padding-right: 135px;
    }
  }
  .group-button {
    margin-top: 10px;
    @include breakpoint(md) {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 0;
      margin-top: 0;
    }
  }
  .btn {
    padding: 0;
    @include square(34px);
    line-height: 34px;
    text-align: center;
    &:not(:last-child) {
      margin-right: 6px;
    }
  }
  .circle {
    img {
      @include border-radius(50%);
    }
  }
  &:hover {
    .btn {
      color: $clr-white;
    }
    .btn-view, .btn-new {
      background-color: $clr-3;
      border-color: $clr-3;
    }
    .btn-edit, .btn-email {
      background-color: $clr-1;
      border-color: $clr-1;
    }
    .btn-delete {
      background-color: $clr-4;
      border-color: $clr-4;
    }
  }
}
.my-skill {
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 6px;
  }
  .btn {
    margin-top: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    i {
      margin-right: 5px;
    }
  }
}

/* 11.5 Employer Message */
.employer-messages {
  .header-box-admin {
    border-bottom: 1px solid #e4e4e4;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;    
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .body-box-admin {
    @include breakpoint(xl) {
      @include dFlex();
      @include flexWrap();
    }
  }
}
.header-box-right {
  padding-top: 7px;
  padding-bottom: 7px;
  @include breakpoint(md) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @include breakpoint(xl) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include breakpoint(xl) {
    @include flexWidth(305px);
    padding-right: 30px;
  }
}
.conversationer {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  @include breakpoint(md) {
    padding-top: 30px;
    padding-bottom: 30px;
    @include dFlex();
    @include flexWrap();
    align-items: center;
    justify-content: space-between;
  }
  @include breakpoint(xl) {
    @include flexWidth(calc(100% - 305px));
    padding-left: 30px;
    border-left: 1px solid #e4e4e4;
  }
}
.search-friends {
  position: relative;
  padding-right: 55px;
  background-color: $bg-basic-3;
  @include border-radius(4px);
  height: 44px;
  .form-control {
    background-color: transparent;
    border-color: transparent;
    height: 100%;
    &:focus {
      border-color: transparent;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    text-align: center;
    width: 55px;
    color: $clr-1;
  }
}
.chat{
  position: relative;
  @include flexWidth(100%);
  @include breakpoint(md){
    @include dFlex();
  }
}
.contact-chat{
  background-color: #fcfcfc;
  overflow-x: hidden;
  @include breakpoint(md) {
    @include flexWidth(280px);
    border-right: 1px solid #e4e4e4;
  }
  @include breakpoint(xl) {
    @include flexWidth(336px);
  }
}
.msg-contacts {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    .msg-contact-item {
      display: block;
      padding: 12px;
      @include breakpoint(xl){
        padding: 20px 30px;
      }
      &:hover{
        background-color: $clr-white;
      }
      &::after{
      display: block;
      clear: both;
      content: '';
      }
    }
    .avatar-user{
      float: left;
      width: 60px;
    }
    .text{
      float: left;
      width: calc(100% - 60px);
      padding-left: 12px;
      @include breakpoint(md){
        padding-left: 20px;
      }
    }
    .desc-short {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
      color: #9f9f9f;
    }
    .msg-contact-name {
      position: relative;
      padding-right: 25px;
      font-size: 1em;
      font-weight: 600;
      color: #383838;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
      .number-unread {
        position: absolute;
        right: 0;
        top: 2px;
        z-index: 2;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #e4e4e4;
    }
    &.active {
      .msg-contact-item {
        background-color: $clr-white;
      }
    }
  }
}

.content-chat {
  color: $clr-2;
  display: none;
  @include breakpoint(md) {
    @include flexWidth(calc(100% - 280px));
    display: block;
  }
  @include breakpoint(xl) {
    @include flexWidth(calc(100% - 336px));
  }
  .list {
    padding: 15px;
    @include breakpoint(xl) {
      padding: 30px;
    }
  }
}

.chat-item {
  margin-bottom: 15px;
  @include breakpoint(md){
    @include dFlex();
    @include flexWrap();
    margin-bottom: 10px;
  }
  .avatar {
    display: none;
    @include breakpoint(md){
      @include flexWidth(60px);
      display: block;
    }
    img {
      @include square(60px);
      @include border-radius(50%);
    }
  }
  .text {
    @include breakpoint(md){
      @include flexWidth(calc(100% - 60px));
    }
    .time {
      text-transform: uppercase;
      color: $clr-2;
      font-size: 1em;
      @include breakpoint(md){
        margin-bottom: 7px;
      }
    }
    .content {
      display: inline-block;
      padding: 7px 10px;
      @include border-radius(5px);
      @include breakpoint(md){
        padding: 15px 20px;
      }
    }
  }
  &.green {
    .text {
      padding-left: 20px;
      .content {
        background-color: $clr-1;
        color: $clr-white;
      }
    }
  }

  &.gray {
    @include justifyContent(flex-end);
    .avatar {
      -ms-flex-order: 12;
      order: 12;
    }
    .text {
      padding-right: 20px;
      text-align: right;
      .content {
        background-color: $bg-basic-3;
        color: $clr-2;
      }
    }
  }
}
.user-main {
  @include dFlex();
  .img {
    @include flexWidth(60px);
    margin-right: 20px;
    img {
      @include border-radius(50%);
    }
  }
  .text {
    @include flexWidth(calc(100% - 80px));
    h4 {
      font-weight: 600;
      font-size: 1.125em;
      margin-bottom: 0;
    }
    .user-status {
      color: $clr-2;
    }
    .status {
      display: inline-block;
      @include border-radius(50%);
      @include square(14px);
      background-color: $clr-1;
      vertical-align: -1px;
      &.online {
        background-color: $clr-1;
      }
      &.alway {
        background-color: #ffb13c;
      }
      &.invisible-cs {
        border: 2px solid #ddd;
        background-color: $clr-white;
      }
    }
  }
}
.back-view{
  display: none;
}
.conversation-mb{
  @media (max-width: 767px) {
    .conversationer{
      padding-left: 35px;
    }
    .user-main .img{
      display: none;
    }
    .back-view{      
      display: block;
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 100%;
      left: -5px;
      color: $clr-basic-text;
      width: 30px;
      cursor: pointer;
      i{
        font-size: 1.25em;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .content-chat {
      display: block;
    }
    .contact-chat {
      display: none;
    }
    .header-box-admin {
      display: block !important;
    }
    .header-box-right{
      display: none;
    }
  }  
}

.delete-conversation {
  @media (max-width: 767px) {
    margin-top: 4px;
  }
  button {
    color: $clr-4;
    i {
      margin-right: 4px;
    }
  }
}
.time-line {
  position: relative;
  text-align: center;
  margin: 23px 0 25px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e4e4e4;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
  }
  span {
    display: inline-block;
    padding: 0 20px;
    background-color: $clr-white;
    position: relative;
    z-index: 2;
  }
}
.send-message {
  padding: 15px;
  border-top: 1px solid #e4e4e4;
  @include breakpoint(md) {
    padding: 30px;
  }
  .inner {
    position: relative;
    padding-right: 50px;
    @include breakpoint(xl) {
      padding-right: 190px;
    }
  }
  .form-control {
    height: 54px;
    border: none;
    padding-left: 0;
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: 54px;
    line-height: 54px;
    width:50px;
    padding: 0;
    text-align: center;
    span{
      display: none;
    }
    @include breakpoint(xl) {
      width: 190px;
      span{
        display: inline-block;
      }
    }
    @media (max-width: 1199px) {
      background-color: transparent;
      color: $clr-1;
      border-color: transparent;
      &:hover, &:focus{
        box-shadow: none;
        background-color: transparent;
        border-color: transparent;
        color: $clr-1;
      }
      &:active{
        color: $clr-1;
      }
    }
  }
}

/* 11.6 Employer Transactions */
.my-transactions {
  .header-box-admin {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.transaction-item {
  color: $clr-2;
  .job-title {
    color: $clr-basic-text;
  }
  &:hover {
    background-color: #fcfcfc;
  }
}
.transction-profile {
  @include dFlex();
  @include alignItems(center);
  min-width: 230px;
  .avatar {
    @include flexWidth(60px);
    img {
      @include border-radius(0);
    }
  }
  .text {
    @include flexWidth(calc(100% - 60px));
    padding-left: 20px;
    h4 {
      font-weight: 600;
      font-size: 1em;
      margin-bottom: 0;
      color: $clr-basic-text;
      a {
        color: $clr-basic-text;;
        &:hover {
          color: $clr-1;
        }
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

.tbl-transactions {
  margin-bottom: 0;
  thead {
    th {
      white-space: nowrap;
      border-bottom-width: 1px;
      font-weight: 600;
      border-top: none;
      padding-bottom: 15px;
    }
  }
  td, th {
    vertical-align: middle;
    border-color: #e4e4e4;
    padding: 15px 8px;
    @include breakpoint(md){
      padding: 20px 15px;
    }
    &:first-child {
      padding-left: 15px;
      @include breakpoint(md) {
        padding-left: 30px;
      }
    }
    &:last-child {
      padding-right: 15px;
      @include breakpoint(md) {
        padding-right: 30px;
      }
    }
  }
}

/* 11.6 Employer Transactions */
/* 11.6 Employer Transactions */