/*------------------------------------------------------------------
[17. Error]
*/
.error-page-message{
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 65px;
  padding-bottom: 75px;
  @include breakpoint(md){
    padding-top: 130px;
    padding-bottom: 150px;
  }
  .msg1{
    font-size: 9.375em;
    color: $clr-1;
    line-height: 1;
    margin-bottom: 6px;
    text-align: center;
    @include breakpoint(md){
      font-size: 15em;
    }
  }
  .msg2{
    font-size: 1.375em;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    @include breakpoint(md){
      font-size: 1.75em;
    }
  }
  .msg3{
    color: $clr-2;
    text-align: center;
    margin-bottom: 0;
  }
  .error-page-search{
    max-width: 542px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
  }
}
.search-form-2{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .form-control{
    height: 54px;
    padding: 14px 20px;
    line-height: 24px;
    color: $clr-2;
    border-color: $clr-white;
    border-radius: 4px;
    flex: 1 1 0px;
    min-width: 0;
    &:focus{
      border-color: $bg-basic-1;
    }
  }
  button{
    background-color: $clr-1;
    color: $clr-white;
    height: 54px;
    border-radius: 4px;
    margin-left: 12px;
  }
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    button{
      margin-top: 12px;
      margin-left: 0;
    }
  }
}

