/*------------------------------------------------------------------
[2. Header]
*/
/* 2.1 Header layout */
.header-site{
	border-bottom: 1px solid rgba(#e4e4e4,1);
	color: $clr-basic-text;
	background-color: $clr-white;
  	margin-bottom: 42px;
	a{
		color: $clr-basic-text;
	}
	.home & {
		border-bottom: 1px solid rgba($clr-white,.2);
		color: $clr-white;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
    z-index: 10;
    background-color: transparent;
		a{
			color: $clr-white;
    }
    .btn-toggle{
      color: $clr-white;
    }
	}
}
.qdesk-logo{
	.qdesk-logo-white{
		display: none;
	}
	.home & {
		.qdesk-logo-white{
			display: block;
		}
		.qdesk-logo-black{
			display: none;
		}
	}
}
.header-wrap{
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	min-height: 60px;
	position: relative;
}
.header-left{
	@include breakpoint(lg){
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		flex: 1 1 0px;
		align-items: center;
	}
	@media (max-width: 991px) {
		position: relative;
		padding-left: 50px;
	}
	.header-logo{
		@include breakpoint(lg){
			@include flexWidth(110px);
		}
    @include breakpoint(xl){
      @include flexWidth(140px);
    }
	}
}
.header-right{
  @include breakpoint(xl){
    padding-right: 13px;
  }
  @media (max-width: 767px) {
    display: none;
  }
	a{
		&:hover{
			color: $clr-link;
		}
	}
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		li{
			font-weight: 600;
			display: inline-block;
			a{
				display: inline-block;
			}
			& + li{
				margin-left: 15px;
				@include breakpoint(xl){
					margin-left: 30px;
				}
			}
		}
	}
}
.user-profile{
  list-style: none;
  margin-bottom: 0;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
  li{
    &:not(:last-child){
      margin-bottom: 10px;
    }
    a{
      color: $clr-basic-text;
    }
  }
}
/* 2.2 User/.header-right-logined */
.header-right-logined{
	display: flex;
	align-items: center;
	position: relative;
	.header-user{
		padding: 0;
		margin-left: 60px;
		@media screen and (max-width: 1023px) and (min-width: 992px) {
			margin-left: 10px;
		}
		.avatar{
			position: relative;
			@media (max-width: 991px) {
				@include flexWidth(40px);
			}
			img{
				@media (max-width: 991px) {
					max-height: 40px;
					width: 40px;
					height: 40px;
				}
			}
		}
		.status{
			display: inline-block;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 1px solid $clr-white;
			position: absolute;
			right: 0;
			bottom: 1px;
			&.offline{
				background-color: $clr-2;
			}
			&.active{
				background-color: $clr-1;
			}
		}
		.info-user{
			flex: 1 1 0px;
			max-width: none;
			h3{
				font-size: 1em;
			}
		}
	}
}
.notification-item, .message-item{
	display: inline-block;
	height: 26px;
	width: 30px;
	position: relative;
	margin-top: 8px;
	i{
		font-size: 1.5em;
	}
}
.message-item{
	margin-left: 30px;
	margin-top: 10px;
	@media screen and (max-width: 1023px) and (min-width: 992px) {
		margin-left: 15px;
	}
}
.numeric{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: $bg-basic-1;
	border-radius: 50%;
	color: $clr-white;
	font-weight: 600;
	font-size: 0.875em;
	line-height: 20px;
	text-align: center;
	position: absolute;
	right: 0;
	top: -7px;
}
.user-profile-dropdown{
	position: absolute;
	min-width: 200px;
	top: 100%;
	right: 0;
	z-index: 10;
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
		background-color: $clr-white;
		border-radius: 4px;
		border: 1px solid #e4e4e4;
		box-shadow: 0 0 6px rgba($clr-black, .2);
		li{
			display: block !important;
			margin-left: 0 !important;
			&:not(:last-child){
				border-bottom: 1px solid #e4e4e4;
			}
			a{
				display: block;
				padding: 5px 10px;
				&:hover{
					background-color: #f4f4f4;
				}
			}
		}
	}
}