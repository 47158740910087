/*------------------------------------------------------------------
[4. Content]
*/
section{
  display: block;
}
/* section heading */
.section-heading{
  margin-bottom: 25px;
  @include breakpoint(md){
    margin-bottom: 40px;
  }
  .breadcrumb {
		margin-bottom: 0;
	}
}
.title{
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 1.75em;
  line-height: 1.357142;
  a{
    color: inherit;
    &:hover{
      color: $clr-1;
    }
  }
}
.title-page {
	font-size: 1.875em;
	color: $bg-basic-2;
	font-weight: normal;
	margin-bottom: 4px;
}
.search-form {
	position: relative;
	padding-right: 55px;
  margin-bottom: 20px;
  .header-page & {
    @include breakpoint(md){
      margin-top: 12px;  
    }
  }
	button {
		background-color: $clr-1;
		color: $clr-white;
		width: 44px;
		height: 44px;
		@include border-radius(4px);
		position: absolute;
		top: 0;
		right: 0;
		border: none;
	}
	.form-control {
		height: 44px;
		border-color: $clr-white;
		padding-left: 20px;
		color: $clr-2;
		@include placeholder($clr-2);
		&:focus {
			outline: none;
			@include box-shadow(none);
		}
	}
}

/*----------- section section-banner -----------*/
.section-banner{
  position: relative;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  .banner-content{
    position: relative;
    padding-top: 155px;
    padding-bottom: 120px;
    @include breakpoint(lg){
      padding-top: 327px;
      padding-bottom: 217px;
    }
    &::before{
      content: "";
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba($clr-black,.6);
    }
    &>.container{
      position: relative;
      z-index: 1;
    }
  }
}
.banner-item{
  .banner-title{
    font-size: 1.875em;
    color: $clr-white;
    text-align: center;
    margin-bottom: 16px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    @include breakpoint(lg){
      font-size: 3.125em;
    }
  }
  .banner-sub-title{
    max-width: 730px;
    text-align: center;
    color: $clr-white;
    font-family: 'Nunito Sans', sans-serif;
    margin: 0 auto 45px auto;
  }
  .search-form-adv{
    .form-control{
      background-color: rgba($clr-white,.08);
      border-color: rgba($clr-white,.08);
      height: 54px;
      line-height: 24px;
      padding-top: 15px;
      padding-bottom: 15px;
      @include breakpoint(md){
        border-radius: 0;
      }
      &:focus{
        box-shadow: none;
        border-color: $clr-1;
      }
    }
    .btn{
      height: 54px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .search-key{
      @include breakpoint(md){
        border-right: 1px solid rgba($clr-white,.2);
      }
      .form-control{
        @include breakpoint(md){
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }
    .search-location{
      @include breakpoint(md){
        border-right: 1px solid rgba($clr-white,.2);
      }
    }
  }
  .search-form-submit{
    text-align: right;
    @include breakpoint(lg){
      margin-left: 10px;
      text-align: left;
    }
  }
}
.select-hidden{
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.smart-search-category{
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 1em;
  color: $clr-2;
  width: 100%;
  height: 54px;
}
.smart-search-category-styled{
  position: absolute; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($clr-white,.08);
  height: 54px;
  line-height: 24px;
  padding: 15px .75rem;
  @include transition(all 0.2s ease-in);
  border-width: 1px;
  border-style: solid;
  border-color: transparent rgba($clr-white,.08) transparent transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &:after {
    content:"";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: $clr-white transparent transparent transparent;
    position: absolute;
    top: 24px;
    right: 30px;
  }
  &:focus {
    border-color: $bg-basic-1;
  }
  &:active, &.active {
    border-color: $bg-basic-1;
    &:after {
      top: 18px;
      border-color: transparent transparent $clr-white transparent;
    }
  }
}

.select-options {
  display: none; 
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: rgba($clr-white, .08);
  li {
    margin: 0;
    padding: 12px;
    @include transition(all 0.15s ease-in);
    &:hover {
      color: $clr-white;
      background: rgba($clr-white, .5);
    }
    &[rel="hide"] {
      display: none;
    }
    &:not(:last-child){
      border-bottom: 1px solid rgba($clr-white, .3);
    }
  }
}

/*----------- section popular-category -----------*/
.popular-category{
  padding: 50px 0;
  @include breakpoint(md){
    padding-top: 93px;
    padding-bottom: 100px;
  }
}
.category-list{
  margin-bottom: 25px;
  padding-top: 5px;
  @include breakpoint(md){
    margin-bottom: 50px;
  }
  &>[class*="col-"]{
    margin-bottom: 0;
  }
}
.category-single{
  position: relative;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  transition: all .3s;
  @include breakpoint(md){
    padding: 27px 15px 22px 0;
  }
  .category-single-icon{
    @include flexWidth(80px);
    text-align: center;
    display: inline-block;
    margin-top: -10px;
    padding: 0 15px;
    @include breakpoint(md) {
      @include flexWidth(112px);
    }
    i{
      font-size: 2.9375em;
      color: $clr-2;
    }
  }
  .category-signle-content{
    @include flexWidth(calc(100% - 80px));
    @include breakpoint(md) {
      @include flexWidth(calc(100% - 112px));
    }
    .count{
      color: $clr-1;
      display: inline-block;
      margin-left: 3px;
    }
    h3{
      font-size: 1em;
      margin-bottom: 3px;
      font-weight: 700;
      @include breakpoint(md){
        font-weight: 600;
        margin-bottom: 7px;
      }
    }
    .text{
      color: $clr-2;
    }
  }
  &:hover{
    background-color: $bg-basic-1;
    box-shadow: 0 0 10px rgba($bg-basic-1, .6);
    color: $clr-white;
    .count, .text{
      color: $clr-white;
    }
    .category-single-icon{
      i{
        color: $clr-white;
      }
    }
  }
}

/*----------- section section-video -----------*/
.section-video{
  position: relative;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  color: $clr-white;
  position: relative;
  &::before{
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(#191919,.5);
  }
  &>.container{
    position: relative;
    z-index: 1;
  }
  .video-container{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    @include breakpoint(md){
      padding-right: 105px;
    }
    @include breakpoint(lg){
      padding-top: 94px;
      padding-bottom: 100px;
      padding-left: 15px;
    }
    .play-circle{
      position: absolute;
      display: block;
      right: 0;
      bottom: 40px;
      @include breakpoint(sm){
        margin: 0;
        right: 15px;
        z-index: 2;
        top: calc(100% - 110px);
      }
      @include breakpoint(md){
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .section-heading{
      @include breakpoint(md){
        margin-bottom: 37px;
      }
    }
  }
  .video-content{
    max-width: 540px;
    .intro{
      margin-bottom: 23px;
      @include breakpoint(md){
        margin-bottom: 47px;
      }
    }
  }
}
.play-circle{
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 10px;
  border-radius: 50%;
  background: rgba($bg-basic-1, .3);
  position: relative;
  vertical-align: middle;
  color: $clr-white;
  &::before{
    content: '';
    display: block;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: rgba($bg-basic-1, .5);
    position: absolute;
    top: 5px;
    left: 5px;
    transition: background .2s;
  }
  i{
    width: 44px;
    height: 44px;
    background-color: $bg-basic-1;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 1;
    font-size: 1.875em;
    line-height: 44px;
    @include breakpoint(md){
      font-size: 2.5em;
    }
  }
  &:hover{
    &::before{
      background: rgba($bg-basic-1, .8);
    }
  }
  @include breakpoint(md){
    width: 90px;
    height: 90px;
    &::before{
      width: 80px;
      height: 80px;
    }
    i{
      width: 70px;
      height: 70px;
      line-height: 70px;
    }
  }
}

/*----------- section section-pricing -----------*/
.section-pricing{
  padding: 50px 0;
  @include breakpoint(md){
    padding-bottom: 100px;
    padding-top: 93px;
  }
  .pricing-col{
    @media (max-width: 991px) {
      margin-bottom: 25px;
    }
    &:first-child{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:not(:last-child){
      border-right: 1px solid #f4f4f4;
    }
  }
}
.pricing-page {
  .pricing-col {
    margin-bottom: 25px;
    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }
}

.bg-grey{
  background-color: #f4f4f4;
}
.best-pricing{
  position: relative;
  min-width: 220px;
  max-width: 520px;
  margin: 0 auto 45px;
  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
  }
  .rdo-lbl {
    position: relative;
    z-index: 3;
    font-weight: 600;
    cursor: pointer;
    color: $clr-2;
    transition: 500ms;
    margin-bottom: 0;
    &:hover, &:active{
      color: #383838;
    }
  }
  .rdo-lbl-left {
    left: 15px;
    padding-right: 30px;
  }
  .rdo-lbl-right {
    left: -15px;	
    padding-left: 30px;
    span{
      color: $clr-1;
    }
  }
}
.toggle-pill {
	position: relative;
	height: 10px;
	width: 35px;
	background: #e6e6e6;
	border-radius: 500px;
  transition: all 500ms;
  border-radius: 5px;
}
.toggle-circle {
	position: absolute;
	width: 16px;
	height: 16px;
  background-color: $bg-basic-1;
  border: 1px solid $clr-white;
  left: 0;
  margin: -3px 0;
	transition: all 500ms;
  z-index: 4;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba($bg-basic-1, .6);
}
#billMonth:checked~.rdo-lbl-left {
	color: #383838;
}
#billYear:checked~.rdo-lbl-left {
	color: $clr-2;
}
#billYear:checked~.rdo-lbl-right {
	color: #383838;
}
#billYear:checked~.toggle-pill{
  .toggle-circle{
    left: calc(100% - 16px);
  }
}
.pricing-box{
  background-color: $clr-white;
  padding: 20px;
  @include breakpoint(md){
    padding: 36px 30px 40px;
  }
  @include breakpoint(sm){
    height: 100%;
  }
  .pricing-heading{
    margin-bottom: 21px;
    .pricing-title{
      font-size: 1.125em;
      font-weight: 600;
      margin-bottom: 2px;
    }
    .pricing-sub-title{
      color: $clr-2;
    }
  }
  .pricing-price{
    h1{
      font-size: 1.875em;
      font-weight: bold;
      margin-bottom: 19px;
    }
  }
  .pricing-list{
    margin-bottom: 25px;
    ul{
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      li{
        color: $clr-2;
        position: relative;
        padding-left: 23px;
        &::before{
          position: absolute;
          top: 4px;
          left: 0;
          width: 16px;
          height: 16px;
          background-color: $bg-basic-1;
          border-radius: 50%;
          display: inline-block;
          content: "\f00c";
          color: $clr-white;
          font-size: 0.625em;
          font-family: 'Font Awesome 5 Free';
          font-style: normal;
          font-weight: 900;
          text-align: center;
          line-height: 16px;
        }
        &:not(:last-child){
          margin-bottom: 4px;
        }
      }
    }
  }
  &.pricing-box-fearured{
    background-color: $bg-basic-1;
    color: $clr-white;
    box-shadow: 0 0 10px rgba($clr-1,.6);
    .pricing-heading{
      .pricing-sub-title{
        color: $clr-white;
      }
    }
    .pricing-list ul li{
      color: $clr-white;
      &::before{
        background-color: $clr-white;
        color: $clr-1;
      }
    }
    .btn-light {
      background-color: $clr-white;
      border-color: $clr-white;
      color: $clr-1;
      &:hover {
        background-color: darken(#f1f1f1,5%);
        border-color: darken(#f1f1f1,5%);
      }
      &:active {
        color: $clr-white;
      }
    }
  }
}

/*----------- section how-it-work -----------*/
.how-it-work{
  padding-top: 50px;
  padding-bottom: 50px;
  @include breakpoint(md){
    padding-top: 93px;
    padding-bottom: 95px;
  }
  .section-heading{
    margin-bottom: 23px;
  }
}
.how-it-work-box{
  margin-bottom: 20px;
  @include breakpoint(md){
    margin-bottom: 0;
  }
  .img{
    text-align: center;
    color: $clr-1;
    display: block;
    margin-bottom: 6px;
    @media (max-width: 575px) {
      line-height: 1.25;
    }
    i{
      font-size: 3.75em;
    }
  }
  h3{
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 1.125em;
    text-align: center;
    margin-bottom: 7px;
    @include breakpoint(md){
      margin-bottom: 17px;
    }
  }
  .text{
    color: $clr-2;
  }
}

/*----------- section section-testmonial -----------*/
.section-testmonial{
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  color: $clr-white;
  position: relative;
  padding: 50px 0;
  margin-bottom: 50px;
  @include breakpoint(md){
    padding-bottom: 94px;
    padding-top: 100px;
    margin-bottom: 93px;
  }
  &::before{
    content: "";
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(#191919,.6);
    display: block;
  }
  &>.container{
    position: relative;
    z-index: 1;
  }
}
.testmonial-single{
  position: relative;
  padding-top: 45px;
  @include breakpoint(md){
    padding-top: 84px;
  }
  &::before{
    content: '\e807';
    position: absolute;
    top: 0;
    left: 50%;
    color: $clr-1;
    font-size: 2.25em;
    line-height: 1;
    transform: translateX(-50%);
    display: inline-block;
    font-family: 'flaticon-category';
    font-weight: normal;
    font-style: normal;
    @include breakpoint(md){
      font-size: 2.8125em;
    }
  }
  blockquote{
    border: 0;
    padding: 0;
    margin: 0 0 18px 0;
    font-size: 1.25em;
    text-align: center;
    position: relative;
    line-height: 1.4;
    @include breakpoint(md){
      margin-bottom: 36px;
      font-size: 1.375em;
    }
  }
  .testmonial-author{
    text-align: center;
    font-size: 1.125em;
  }
}

/*----------- section section-news -----------*/
.section-news{
  margin-bottom: 30px;
  @include breakpoint(md){
    margin-bottom: 70px;
    .section-heading{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .section-heading a{
    @media (max-width: 767px) {
      display: none;
    }
  }
  .card-info{
    margin-top: 5px;
  }
}
.card-info{
  margin-bottom: 25px;
  .meta{
    margin-bottom: 2px;
  }
}
.card-info-img{
  margin-bottom: 23px;
  img{
    display: block;
    width: 100%;
    border-radius: 5px;
  }
}
.meta{
  display: inline-block;
  color: #383838;
}
.card-info-title{
  color: #383838;
  font-size: 1.25em;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 18px;
  a{
    color: inherit;
    &:hover{
      color: $clr-1;
    }
  }
}
.card-info-text{
  color: $clr-2;
}