/*------------------------------------------------------------------
[8. Pagination]
*/
.in-pagination{
	padding-top: 20px;
	.pagination{
		margin-bottom: 0;
	}
  .page-link {
    margin-left: 5px;
    margin-right: 5px;
    line-height: $line-height-base;
    color: $clr-2;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 10px;
    border: none;
    &:hover{
      z-index: 2;
      color: $clr-white;
      background-color: #383838;
    }
    @include breakpoint(md){
      padding: 9px 17px;
    }
  }
  .page-item.active .page-link {
    z-index: 3;
    color: $clr-white;
    background-color:$bg-basic-1;
  }
}
.pagination-page {
	padding: 13px 30px 0;
	@include dFlex();
	@include flexWrap();
	@include justifyContent(center);
	@include alignItems(center);
	@include breakpoint(md){
		@include justifyContent(space-between);
	}
	.dropdown-custom {
		margin-bottom: 16px;
	}
	.page-link {
		padding: 0 10px;
		color: $clr-2;
		border: none;
		&:hover {
			background-color: transparent;
			color: $clr-1;
		}
	}
	.page-item {
		&.active {
			.page-link {
				background-color: transparent;
				color: $clr-1;
			}
		}
		&:not(.disabled) {
			&.page-prev, &.page-next {
				.page-link {
					font-weight: 600;
					color: $clr-basic-text;
				}
			}
		}
	}
}
.blog-style-1{
	.pagination{
		justify-content: center;
	}
}
