/*------------------------------------------------------------------
[3. Navigation]
*/
.navigation{
  @include flexWidth(calc(100% - 118px));
  @include breakpoint(xl){
    @include flexWidth(calc(100% - 140px));
  }
	@media (max-width: 991px) {
		display: none;
	}
}
.main-menu{
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	&>li{
		position: relative;
		&>a{
			display: inline-block;
			padding: 35px 10px;
			// color: $clr-white;
			transition: all 0.3s;
			font-weight: 600;
			line-height: 29px;
			@include breakpoint(xl){
				padding-left: 15px;
				padding-right: 15px;
			}
		}
		&:hover{
			&>a{
				color: $clr-link;
			}
			.sub-menu{
				top: 100%;
				opacity: 1;
				visibility: visible;
			}
		}
		&.active{
			&>a{
				color: $clr-link;
			}
		}
	}
}
.sub-menu{
	position: absolute;
	left: 0;
	z-index: 9;
	top: 100%;
	padding: 10px 20px;
	min-width: 160px;
	background: $clr-white;
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease-in-out;
	box-shadow: 0 0 6px rgba($clr-black,.3);
	list-style: none;
	margin: 0;
	&>li{
		&>a{
			display: block;
			padding: 5px 0px;
			font-weight: 600;
			color: $clr-basic-text !important;
			white-space: nowrap;
			transition: all 0.3s;
			&:hover{
				color: $clr-link !important;
			}
		}
		&:not(:last-child){
			&>a{
				border-bottom: 1px solid $bg-basic-3;
			}
		}
	}
}
.header-main-toggle{
	margin: 0 0 0 15px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	@include breakpoint(lg){
		display: none;
	}
  .btn-toggle{
    background-color: transparent;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;
    font-size: 22px;
  }
}
.menu-mobile-wrap{
	height: 100%;
	position: fixed;
	top: 0;
	transition: left, .3s;
	width: 275px;
	z-index: 999;
	display: block;
  left: -275px;
	&::before{
		background: rgba($clr-black, .6);
		content: "";
		display: none;
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		width: 100%;
    z-index: -1;
	}
  .menu-mobile-content{
    background-color: $clr-white;
    position: relative;
    height: 100%;
    z-index: 1;
  }
	.menu-mobile{
		background-color: $clr-white;
		overflow: hidden;
		overflow-y: auto;
		position: relative;
	}
	&.open{
		left: 0;
		&::before{
			display: block;
			opacity: 1;
		}
		.btn-menu-close{
			opacity: 1;
		}
	}
}
.menu-mobile-profile{
  .line{
    background-color: $bg-basic-1;
    padding: 8px;
    text-align: right;
  }
  &.after-logined{
	  .user-profile{
		display: none;
	  }
  }
}
.btn-menu-close{
	width: 25px;
	height: 25px;
	display: inline-block;
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
	position: relative;
	vertical-align: middle;
	&::before, &::after{
		background: $clr-white;
		content: '';
		height: 2px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		width: 20px;
		transition: all 0.3s ease;
	}
	&::before{
		-webkit-transform: rotate(45deg);
		-webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
		top: 50%;
		margin-top: -2px;
	}
	&::after{
		-webkit-transform: rotate(-45deg);
		-webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
		top: 50%;
		margin-top: -2px;
	}
}
.main-menu-mobile{
	padding: 15px;
	list-style: none;
	margin-bottom: 0;
	&>li{
		&>a{
			display: block;
			border-bottom: 1px solid #e2e2e2;
			color: $clr-basic-text;
			padding: 10px 0;
			position: relative;
			font-weight: 600;
			&:hover{
				color: $clr-link-hover;
			}
			&::before{
				font-weight: 900;
				font-size: 14px;
				font-family: 'Font Awesome 5 Free';
				line-height: 1;
				top: 15px;
				display: block;
				position: absolute;
				right: 10px;
			}
			&[aria-expanded="false"]{
				&::before{
					content: '\f054';
				}
			}
			&[aria-expanded="true"]{
				&::before{
					content: '\f078';
				}
			}
		}
	}
}
.sub-menu-mobile{
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;
	&>li{
		padding-left: 15px;
		&>a{
			display: block;
			position: relative;
			padding:8px 0;
			color: $clr-black;
			&:hover{
				color: $clr-1;
			}
		}
	}
}