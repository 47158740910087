/*------------------------------------------------------------------
[1. Body]
*/
body{
  color: $clr-basic-text;
  font-size: $font-size-base-mb;
  font-family: 'Nunito Sans', sans-serif;
  line-height: $line-height-base;
  background-color: $bg-basic-3;
  @include breakpoint(md){
    font-size: $font-size-base;
  }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  line-height: 1.3;
  font-weight: bold;
}
h1, .h1 {
  font-size: 1.875em;
}

h2, .h2 {
  font-size: 1.75em;
}

h3, .h3 {
  font-size: 1.25em;
}

h4, .h4 {
  font-size: 1.125em;
}
img{
  max-width: 100%;
  height: auto;
  width: auto;
}
a{
  color: $clr-link;
  &:hover, &:focus{
    text-decoration: none;
    color: $clr-link-hover;
  }
  &:hover{
    img{
      opacity: .85;
    }
  }
}
.text-green {
  color: $clr-1;
}
.btn-clear {
  border: none;
  background-color: transparent;
  padding: 0;
}
button, a {
  &:focus {
    outline: none;
  }
}
.container{
  @include breakpoint(xl){
    max-width: 1170px;
  }
  .primary-page &{
    @include breakpoint(xl){
      max-width: 1140px;
    }
  }
}
.row-md{
  margin-left: -10px;
  margin-bottom: -10px;
  & > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.btn {
  padding: 9px 20px;
  line-height: 1.5;
  @include breakpoint(xl){
    padding-left: 30px;
    padding-right: 30px;
  }
  &:focus{
    outline: 0;
    box-shadow: none;
  }
}
.btn-light-green {
  background-color: $clr-1;
  border-color: $clr-1;
  color: $clr-white;
  &:hover {
    background-color: darken($clr-1,10%);
    color: $clr-white;
  }
  &:active {
    color: $clr-white;
  }
}
.btn-light {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: $clr-2;
  &:hover {
    background-color: $clr-3;
    border-color: $clr-3;
    color: $clr-white;
  }
}
.btn-white {
  background-color: $clr-white;
  border-color: $clr-white;
  color: $clr-1;
  &:hover {
    background-color: darken(#f1f1f1,5%);
    border-color: darken(#f1f1f1,5%);
  }
  &:active {
    color: $clr-white;
  }
}
.form-control, .btn {
  font-size: 1em;
}
.fz-18 {
  font-size: 1.125em !important;
}
.d-xxl-table-cell{
  @media (min-width: 1366px) {
    display: table-cell !important;
  }
}
.d-xxl-none{
  @media (min-width: 1366px) {
    display: none !important;
  }
}