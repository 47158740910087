/*------------------------------------------------------------------
[Table of contents]

1. Body
2. Header / #header
3. Navigation / #navigation
4. Content / #content
5. Breadcrumd / breadcrumb
6. Left column / #leftcolumn
7. Right column / #rightcolumn
8. Pagination / .pagination-page
9. Job List / .list-jobs
10. Employer
11. Admin
    11.1 Dashboard
    11.2 Profile
    11.3 Manage Jobs
    11.4 Manage candidates
    11.5 Employer Message
    11.6 Employer Transactions
12. Candidate
13. Blog
14. Form
15. FAQ
16. Checkout
17. Error
18. Contact
19. Footer / #footer
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[1. Body]
*/
body {
  color: #383838;
  font-size: 15px;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 1.625;
  background-color: #f4f4f4;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-weight: bold;
}

h1, .h1 {
  font-size: 1.875em;
}

h2, .h2 {
  font-size: 1.75em;
}

h3, .h3 {
  font-size: 1.25em;
}

h4, .h4 {
  font-size: 1.125em;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

a {
  color: #63ba16;
}

a:hover, a:focus {
  text-decoration: none;
  color: #383838;
}

a:hover img {
  opacity: .85;
}

.text-green {
  color: #63ba16;
}

.btn-clear {
  border: none;
  background-color: transparent;
  padding: 0;
}

button:focus, a:focus {
  outline: none;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1200px) {
  .primary-page .container {
    max-width: 1140px;
  }
}

.row-md {
  margin-left: -10px;
  margin-bottom: -10px;
}

.row-md > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.btn {
  padding: 9px 20px;
  line-height: 1.5;
}

@media only screen and (min-width: 1200px) {
  .btn {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-light-green {
  background-color: #63ba16;
  border-color: #63ba16;
  color: #ffffff;
}

.btn-light-green:hover {
  background-color: #4b8c11;
  color: #ffffff;
}

.btn-light-green:active {
  color: #ffffff;
}

.btn-light {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #9f9f9f;
}

.btn-light:hover {
  background-color: #ffb13c;
  border-color: #ffb13c;
  color: #ffffff;
}

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #63ba16;
}

.btn-white:hover {
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}

.btn-white:active {
  color: #ffffff;
}

.form-control, .btn {
  font-size: 1em;
}

.fz-18 {
  font-size: 1.125em !important;
}

@media (min-width: 1366px) {
  .d-xxl-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 1366px) {
  .d-xxl-none {
    display: none !important;
  }
}

/*------------------------------------------------------------------
[2. Header]
*/
/* 2.1 Header layout */
.header-site {
  border-bottom: 1px solid #e4e4e4;
  color: #383838;
  background-color: #ffffff;
  margin-bottom: 42px;
}

.header-site a {
  color: #383838;
}

.home .header-site {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: transparent;
}

.home .header-site a {
  color: #ffffff;
}

.home .header-site .btn-toggle {
  color: #ffffff;
}

.qdesk-logo .qdesk-logo-white {
  display: none;
}

.home .qdesk-logo .qdesk-logo-white {
  display: block;
}

.home .qdesk-logo .qdesk-logo-black {
  display: none;
}

.header-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  position: relative;
}

@media only screen and (min-width: 992px) {
  .header-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex: 1 1 0px;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .header-left {
    position: relative;
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .header-left .header-logo {
    -ms-flex: 0 0 110px;
    flex: 0 0 110px;
    max-width: 110px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-left .header-logo {
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    max-width: 140px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-right {
    padding-right: 13px;
  }
}

@media (max-width: 767px) {
  .header-right {
    display: none;
  }
}

.header-right a:hover {
  color: #63ba16;
}

.header-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-right ul li {
  font-weight: 600;
  display: inline-block;
}

.header-right ul li a {
  display: inline-block;
}

.header-right ul li + li {
  margin-left: 15px;
}

@media only screen and (min-width: 1200px) {
  .header-right ul li + li {
    margin-left: 30px;
  }
}

.user-profile {
  list-style: none;
  margin-bottom: 0;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
}

.user-profile li:not(:last-child) {
  margin-bottom: 10px;
}

.user-profile li a {
  color: #383838;
}

/* 2.2 User/.header-right-logined */
.header-right-logined {
  display: flex;
  align-items: center;
  position: relative;
}

.header-right-logined .header-user {
  padding: 0;
  margin-left: 60px;
}

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .header-right-logined .header-user {
    margin-left: 10px;
  }
}

.header-right-logined .header-user .avatar {
  position: relative;
}

@media (max-width: 991px) {
  .header-right-logined .header-user .avatar {
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    max-width: 40px;
  }
}

@media (max-width: 991px) {
  .header-right-logined .header-user .avatar img {
    max-height: 40px;
    width: 40px;
    height: 40px;
  }
}

.header-right-logined .header-user .status {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 1px;
}

.header-right-logined .header-user .status.offline {
  background-color: #9f9f9f;
}

.header-right-logined .header-user .status.active {
  background-color: #63ba16;
}

.header-right-logined .header-user .info-user {
  flex: 1 1 0px;
  max-width: none;
}

.header-right-logined .header-user .info-user h3 {
  font-size: 1em;
}

.notification-item, .message-item {
  display: inline-block;
  height: 26px;
  width: 30px;
  position: relative;
  margin-top: 8px;
}

.notification-item i, .message-item i {
  font-size: 1.5em;
}

.message-item {
  margin-left: 30px;
  margin-top: 10px;
}

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .message-item {
    margin-left: 15px;
  }
}

.numeric {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #63ba16;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 20px;
  text-align: center;
  position: absolute;
  right: 0;
  top: -7px;
}

.user-profile-dropdown {
  position: absolute;
  min-width: 200px;
  top: 100%;
  right: 0;
  z-index: 10;
}

.user-profile-dropdown ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.user-profile-dropdown ul li {
  display: block !important;
  margin-left: 0 !important;
}

.user-profile-dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.user-profile-dropdown ul li a {
  display: block;
  padding: 5px 10px;
}

.user-profile-dropdown ul li a:hover {
  background-color: #f4f4f4;
}

/*------------------------------------------------------------------
[3. Navigation]
*/
.navigation {
  -ms-flex: 0 0 calc(100% - 118px);
  flex: 0 0 calc(100% - 118px);
  max-width: calc(100% - 118px);
}

@media only screen and (min-width: 1200px) {
  .navigation {
    -ms-flex: 0 0 calc(100% - 140px);
    flex: 0 0 calc(100% - 140px);
    max-width: calc(100% - 140px);
  }
}

@media (max-width: 991px) {
  .navigation {
    display: none;
  }
}

.main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.main-menu > li {
  position: relative;
}

.main-menu > li > a {
  display: inline-block;
  padding: 35px 10px;
  transition: all 0.3s;
  font-weight: 600;
  line-height: 29px;
}

@media only screen and (min-width: 1200px) {
  .main-menu > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-menu > li:hover > a {
  color: #63ba16;
}

.main-menu > li:hover .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.main-menu > li.active > a {
  color: #63ba16;
}

.sub-menu {
  position: absolute;
  left: 0;
  z-index: 9;
  top: 100%;
  padding: 10px 20px;
  min-width: 160px;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  list-style: none;
  margin: 0;
}

.sub-menu > li > a {
  display: block;
  padding: 5px 0px;
  font-weight: 600;
  color: #383838 !important;
  white-space: nowrap;
  transition: all 0.3s;
}

.sub-menu > li > a:hover {
  color: #63ba16 !important;
}

.sub-menu > li:not(:last-child) > a {
  border-bottom: 1px solid #f4f4f4;
}

.header-main-toggle {
  margin: 0 0 0 15px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) {
  .header-main-toggle {
    display: none;
  }
}

.header-main-toggle .btn-toggle {
  background-color: transparent;
  padding: 0;
  margin: 0;
  position: relative;
  border: 0;
  font-size: 22px;
}

.menu-mobile-wrap {
  height: 100%;
  position: fixed;
  top: 0;
  transition: left, .3s;
  width: 275px;
  z-index: 999;
  display: block;
  left: -275px;
}

.menu-mobile-wrap::before {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

.menu-mobile-wrap .menu-mobile-content {
  background-color: #ffffff;
  position: relative;
  height: 100%;
  z-index: 1;
}

.menu-mobile-wrap .menu-mobile {
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}

.menu-mobile-wrap.open {
  left: 0;
}

.menu-mobile-wrap.open::before {
  display: block;
  opacity: 1;
}

.menu-mobile-wrap.open .btn-menu-close {
  opacity: 1;
}

.menu-mobile-profile .line {
  background-color: #63ba16;
  padding: 8px;
  text-align: right;
}

.menu-mobile-profile.after-logined .user-profile {
  display: none;
}

.btn-menu-close {
  width: 25px;
  height: 25px;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  position: relative;
  vertical-align: middle;
}

.btn-menu-close::before, .btn-menu-close::after {
  background: #ffffff;
  content: '';
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 20px;
  transition: all 0.3s ease;
}

.btn-menu-close::before {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  top: 50%;
  margin-top: -2px;
}

.btn-menu-close::after {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
  top: 50%;
  margin-top: -2px;
}

.main-menu-mobile {
  padding: 15px;
  list-style: none;
  margin-bottom: 0;
}

.main-menu-mobile > li > a {
  display: block;
  border-bottom: 1px solid #e2e2e2;
  color: #383838;
  padding: 10px 0;
  position: relative;
  font-weight: 600;
}

.main-menu-mobile > li > a:hover {
  color: #383838;
}

.main-menu-mobile > li > a::before {
  font-weight: 900;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  line-height: 1;
  top: 15px;
  display: block;
  position: absolute;
  right: 10px;
}

.main-menu-mobile > li > a[aria-expanded="false"]::before {
  content: '\f054';
}

.main-menu-mobile > li > a[aria-expanded="true"]::before {
  content: '\f078';
}

.sub-menu-mobile {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.sub-menu-mobile > li {
  padding-left: 15px;
}

.sub-menu-mobile > li > a {
  display: block;
  position: relative;
  padding: 8px 0;
  color: #000000;
}

.sub-menu-mobile > li > a:hover {
  color: #63ba16;
}

/*------------------------------------------------------------------
[4. Content]
*/
section {
  display: block;
}

/* section heading */
.section-heading {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
  .section-heading {
    margin-bottom: 40px;
  }
}

.section-heading .breadcrumb {
  margin-bottom: 0;
}

.title {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 1.75em;
  line-height: 1.357142;
}

.title a {
  color: inherit;
}

.title a:hover {
  color: #63ba16;
}

.title-page {
  font-size: 1.875em;
  color: #383838;
  font-weight: normal;
  margin-bottom: 4px;
}

.search-form {
  position: relative;
  padding-right: 55px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .header-page .search-form {
    margin-top: 12px;
  }
}

.search-form button {
  background-color: #63ba16;
  color: #ffffff;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
}

.search-form .form-control {
  height: 44px;
  border-color: #ffffff;
  padding-left: 20px;
  color: #9f9f9f;
}

.search-form .form-control::-webkit-input-placeholder {
  color: #9f9f9f;
}

.search-form .form-control:-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

.search-form .form-control::-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

.search-form .form-control:-ms-input-placeholder {
  color: #9f9f9f;
}

.search-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

/*----------- section section-banner -----------*/
.section-banner {
  position: relative;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
}

.section-banner .banner-content {
  position: relative;
  padding-top: 155px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 992px) {
  .section-banner .banner-content {
    padding-top: 327px;
    padding-bottom: 217px;
  }
}

.section-banner .banner-content::before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.section-banner .banner-content > .container {
  position: relative;
  z-index: 1;
}

.banner-item .banner-title {
  font-size: 1.875em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
}

@media only screen and (min-width: 992px) {
  .banner-item .banner-title {
    font-size: 3.125em;
  }
}

.banner-item .banner-sub-title {
  max-width: 730px;
  text-align: center;
  color: #ffffff;
  font-family: 'Nunito Sans', sans-serif;
  margin: 0 auto 45px auto;
}

.banner-item .search-form-adv .form-control {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.08);
  height: 54px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .banner-item .search-form-adv .form-control {
    border-radius: 0;
  }
}

.banner-item .search-form-adv .form-control:focus {
  box-shadow: none;
  border-color: #63ba16;
}

.banner-item .search-form-adv .btn {
  height: 54px;
  padding-top: 14px;
  padding-bottom: 14px;
}

@media only screen and (min-width: 768px) {
  .banner-item .search-form-adv .search-key {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@media only screen and (min-width: 768px) {
  .banner-item .search-form-adv .search-key .form-control {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .banner-item .search-form-adv .search-location {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.banner-item .search-form-submit {
  text-align: right;
}

@media only screen and (min-width: 992px) {
  .banner-item .search-form-submit {
    margin-left: 10px;
    text-align: left;
  }
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.smart-search-category {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 1em;
  color: #9f9f9f;
  width: 100%;
  height: 54px;
}

.smart-search-category-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.08);
  height: 54px;
  line-height: 24px;
  padding: 15px .75rem;
  transition: all 0.2s ease-in;
  border-width: 1px;
  border-style: solid;
  border-color: transparent rgba(255, 255, 255, 0.08) transparent transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.smart-search-category-styled:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  top: 24px;
  right: 30px;
}

.smart-search-category-styled:focus {
  border-color: #63ba16;
}

.smart-search-category-styled:active, .smart-search-category-styled.active {
  border-color: #63ba16;
}

.smart-search-category-styled:active:after, .smart-search-category-styled.active:after {
  top: 18px;
  border-color: transparent transparent #ffffff transparent;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.08);
}

.select-options li {
  margin: 0;
  padding: 12px;
  transition: all 0.15s ease-in;
}

.select-options li:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.5);
}

.select-options li[rel="hide"] {
  display: none;
}

.select-options li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

/*----------- section popular-category -----------*/
.popular-category {
  padding: 50px 0;
}

@media only screen and (min-width: 768px) {
  .popular-category {
    padding-top: 93px;
    padding-bottom: 100px;
  }
}

.category-list {
  margin-bottom: 25px;
  padding-top: 5px;
}

@media only screen and (min-width: 768px) {
  .category-list {
    margin-bottom: 50px;
  }
}

.category-list > [class*="col-"] {
  margin-bottom: 0;
}

.category-single {
  position: relative;
  border-radius: 5px;
  padding: 10px 0;
  display: flex;
  transition: all .3s;
}

@media only screen and (min-width: 768px) {
  .category-single {
    padding: 27px 15px 22px 0;
  }
}

.category-single .category-single-icon {
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 80px;
  text-align: center;
  display: inline-block;
  margin-top: -10px;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) {
  .category-single .category-single-icon {
    -ms-flex: 0 0 112px;
    flex: 0 0 112px;
    max-width: 112px;
  }
}

.category-single .category-single-icon i {
  font-size: 2.9375em;
  color: #9f9f9f;
}

.category-single .category-signle-content {
  -ms-flex: 0 0 calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}

@media only screen and (min-width: 768px) {
  .category-single .category-signle-content {
    -ms-flex: 0 0 calc(100% - 112px);
    flex: 0 0 calc(100% - 112px);
    max-width: calc(100% - 112px);
  }
}

.category-single .category-signle-content .count {
  color: #63ba16;
  display: inline-block;
  margin-left: 3px;
}

.category-single .category-signle-content h3 {
  font-size: 1em;
  margin-bottom: 3px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .category-single .category-signle-content h3 {
    font-weight: 600;
    margin-bottom: 7px;
  }
}

.category-single .category-signle-content .text {
  color: #9f9f9f;
}

.category-single:hover {
  background-color: #63ba16;
  box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  color: #ffffff;
}

.category-single:hover .count, .category-single:hover .text {
  color: #ffffff;
}

.category-single:hover .category-single-icon i {
  color: #ffffff;
}

/*----------- section section-video -----------*/
.section-video {
  position: relative;
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  color: #ffffff;
  position: relative;
}

.section-video::before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(25, 25, 25, 0.5);
}

.section-video > .container {
  position: relative;
  z-index: 1;
}

.section-video .video-container {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .section-video .video-container {
    padding-right: 105px;
  }
}

@media only screen and (min-width: 992px) {
  .section-video .video-container {
    padding-top: 94px;
    padding-bottom: 100px;
    padding-left: 15px;
  }
}

.section-video .video-container .play-circle {
  position: absolute;
  display: block;
  right: 0;
  bottom: 40px;
}

@media only screen and (min-width: 576px) {
  .section-video .video-container .play-circle {
    margin: 0;
    right: 15px;
    z-index: 2;
    top: calc(100% - 110px);
  }
}

@media only screen and (min-width: 768px) {
  .section-video .video-container .play-circle {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 768px) {
  .section-video .video-container .section-heading {
    margin-bottom: 37px;
  }
}

.section-video .video-content {
  max-width: 540px;
}

.section-video .video-content .intro {
  margin-bottom: 23px;
}

@media only screen and (min-width: 768px) {
  .section-video .video-content .intro {
    margin-bottom: 47px;
  }
}

.play-circle {
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 10px;
  border-radius: 50%;
  background: rgba(99, 186, 22, 0.3);
  position: relative;
  vertical-align: middle;
  color: #ffffff;
}

.play-circle::before {
  content: '';
  display: block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: rgba(99, 186, 22, 0.5);
  position: absolute;
  top: 5px;
  left: 5px;
  transition: background .2s;
}

.play-circle i {
  width: 44px;
  height: 44px;
  background-color: #63ba16;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: 1.875em;
  line-height: 44px;
}

@media only screen and (min-width: 768px) {
  .play-circle i {
    font-size: 2.5em;
  }
}

.play-circle:hover::before {
  background: rgba(99, 186, 22, 0.8);
}

@media only screen and (min-width: 768px) {
  .play-circle {
    width: 90px;
    height: 90px;
  }
  .play-circle::before {
    width: 80px;
    height: 80px;
  }
  .play-circle i {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

/*----------- section section-pricing -----------*/
.section-pricing {
  padding: 50px 0;
}

@media only screen and (min-width: 768px) {
  .section-pricing {
    padding-bottom: 100px;
    padding-top: 93px;
  }
}

@media (max-width: 991px) {
  .section-pricing .pricing-col {
    margin-bottom: 25px;
  }
}

.section-pricing .pricing-col:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.section-pricing .pricing-col:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.section-pricing .pricing-col:not(:last-child) {
  border-right: 1px solid #f4f4f4;
}

.pricing-page .pricing-col {
  margin-bottom: 25px;
}

@media only screen and (min-width: 576px) {
  .pricing-page .pricing-col {
    margin-bottom: 0;
  }
}

.bg-grey {
  background-color: #f4f4f4;
}

.best-pricing {
  position: relative;
  min-width: 220px;
  max-width: 520px;
  margin: 0 auto 45px;
}

.best-pricing .switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.best-pricing .switch input {
  display: none;
}

.best-pricing .rdo-lbl {
  position: relative;
  z-index: 3;
  font-weight: 600;
  cursor: pointer;
  color: #9f9f9f;
  transition: 500ms;
  margin-bottom: 0;
}

.best-pricing .rdo-lbl:hover, .best-pricing .rdo-lbl:active {
  color: #383838;
}

.best-pricing .rdo-lbl-left {
  left: 15px;
  padding-right: 30px;
}

.best-pricing .rdo-lbl-right {
  left: -15px;
  padding-left: 30px;
}

.best-pricing .rdo-lbl-right span {
  color: #63ba16;
}

.toggle-pill {
  position: relative;
  height: 10px;
  width: 35px;
  background: #e6e6e6;
  border-radius: 500px;
  transition: all 500ms;
  border-radius: 5px;
}

.toggle-circle {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #63ba16;
  border: 1px solid #ffffff;
  left: 0;
  margin: -3px 0;
  transition: all 500ms;
  z-index: 4;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
}

#billMonth:checked ~ .rdo-lbl-left {
  color: #383838;
}

#billYear:checked ~ .rdo-lbl-left {
  color: #9f9f9f;
}

#billYear:checked ~ .rdo-lbl-right {
  color: #383838;
}

#billYear:checked ~ .toggle-pill .toggle-circle {
  left: calc(100% - 16px);
}

.pricing-box {
  background-color: #ffffff;
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .pricing-box {
    padding: 36px 30px 40px;
  }
}

@media only screen and (min-width: 576px) {
  .pricing-box {
    height: 100%;
  }
}

.pricing-box .pricing-heading {
  margin-bottom: 21px;
}

.pricing-box .pricing-heading .pricing-title {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 2px;
}

.pricing-box .pricing-heading .pricing-sub-title {
  color: #9f9f9f;
}

.pricing-box .pricing-price h1 {
  font-size: 1.875em;
  font-weight: bold;
  margin-bottom: 19px;
}

.pricing-box .pricing-list {
  margin-bottom: 25px;
}

.pricing-box .pricing-list ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
}

.pricing-box .pricing-list ul li {
  color: #9f9f9f;
  position: relative;
  padding-left: 23px;
}

.pricing-box .pricing-list ul li::before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #63ba16;
  border-radius: 50%;
  display: inline-block;
  content: "\f00c";
  color: #ffffff;
  font-size: 0.625em;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  text-align: center;
  line-height: 16px;
}

.pricing-box .pricing-list ul li:not(:last-child) {
  margin-bottom: 4px;
}

.pricing-box.pricing-box-fearured {
  background-color: #63ba16;
  color: #ffffff;
  box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
}

.pricing-box.pricing-box-fearured .pricing-heading .pricing-sub-title {
  color: #ffffff;
}

.pricing-box.pricing-box-fearured .pricing-list ul li {
  color: #ffffff;
}

.pricing-box.pricing-box-fearured .pricing-list ul li::before {
  background-color: #ffffff;
  color: #63ba16;
}

.pricing-box.pricing-box-fearured .btn-light {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #63ba16;
}

.pricing-box.pricing-box-fearured .btn-light:hover {
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}

.pricing-box.pricing-box-fearured .btn-light:active {
  color: #ffffff;
}

/*----------- section how-it-work -----------*/
.how-it-work {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .how-it-work {
    padding-top: 93px;
    padding-bottom: 95px;
  }
}

.how-it-work .section-heading {
  margin-bottom: 23px;
}

.how-it-work-box {
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .how-it-work-box {
    margin-bottom: 0;
  }
}

.how-it-work-box .img {
  text-align: center;
  color: #63ba16;
  display: block;
  margin-bottom: 6px;
}

@media (max-width: 575px) {
  .how-it-work-box .img {
    line-height: 1.25;
  }
}

.how-it-work-box .img i {
  font-size: 3.75em;
}

.how-it-work-box h3 {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.125em;
  text-align: center;
  margin-bottom: 7px;
}

@media only screen and (min-width: 768px) {
  .how-it-work-box h3 {
    margin-bottom: 17px;
  }
}

.how-it-work-box .text {
  color: #9f9f9f;
}

/*----------- section section-testmonial -----------*/
.section-testmonial {
  background-attachment: fixed !important;
  background-size: cover !important;
  background-position: center center !important;
  color: #ffffff;
  position: relative;
  padding: 50px 0;
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) {
  .section-testmonial {
    padding-bottom: 94px;
    padding-top: 100px;
    margin-bottom: 93px;
  }
}

.section-testmonial::before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(25, 25, 25, 0.6);
  display: block;
}

.section-testmonial > .container {
  position: relative;
  z-index: 1;
}

.testmonial-single {
  position: relative;
  padding-top: 45px;
}

@media only screen and (min-width: 768px) {
  .testmonial-single {
    padding-top: 84px;
  }
}

.testmonial-single::before {
  content: '\e807';
  position: absolute;
  top: 0;
  left: 50%;
  color: #63ba16;
  font-size: 2.25em;
  line-height: 1;
  transform: translateX(-50%);
  display: inline-block;
  font-family: 'flaticon-category';
  font-weight: normal;
  font-style: normal;
}

@media only screen and (min-width: 768px) {
  .testmonial-single::before {
    font-size: 2.8125em;
  }
}

.testmonial-single blockquote {
  border: 0;
  padding: 0;
  margin: 0 0 18px 0;
  font-size: 1.25em;
  text-align: center;
  position: relative;
  line-height: 1.4;
}

@media only screen and (min-width: 768px) {
  .testmonial-single blockquote {
    margin-bottom: 36px;
    font-size: 1.375em;
  }
}

.testmonial-single .testmonial-author {
  text-align: center;
  font-size: 1.125em;
}

/*----------- section section-news -----------*/
.section-news {
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) {
  .section-news {
    margin-bottom: 70px;
  }
  .section-news .section-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .section-news .section-heading a {
    display: none;
  }
}

.section-news .card-info {
  margin-top: 5px;
}

.card-info {
  margin-bottom: 25px;
}

.card-info .meta {
  margin-bottom: 2px;
}

.card-info-img {
  margin-bottom: 23px;
}

.card-info-img img {
  display: block;
  width: 100%;
  border-radius: 5px;
}

.meta {
  display: inline-block;
  color: #383838;
}

.card-info-title {
  color: #383838;
  font-size: 1.25em;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 18px;
}

.card-info-title a {
  color: inherit;
}

.card-info-title a:hover {
  color: #63ba16;
}

.card-info-text {
  color: #9f9f9f;
}

/*------------------------------------------------------------------
[5. breadcrumb]
*/
.breadcrumb {
  background-color: transparent;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "-";
}

.breadcrumb-item a {
  color: #9f9f9f;
}

.breadcrumb-item a:hover, .breadcrumb-item a:focus {
  color: #63ba16;
}

.breadcrumb-item.active {
  color: #63ba16;
}

/*------------------------------------------------------------------
[6. Left column]
*/
.sidebar-left {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

@media (max-width: 911px) {
  .sidebar-left {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 999;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }
  .sidebar-left.show {
    left: 0;
  }
}

.btn-close-sidebar-left {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.125em;
  color: #63ba16;
  padding: 5px;
}

@media only screen and (min-width: 992px) {
  .btn-close-sidebar-left {
    display: none;
  }
}

.toggle-sidebar-left {
  background-color: #63ba16;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 8px 30px;
  cursor: pointer;
  font-size: 1.125em;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.title-box {
  color: #383838;
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 0;
}

.title-box span {
  color: #63ba16;
}

.box-sidebar {
  padding: 27px 30px 17px;
  border-bottom: 1px solid #e4e4e4;
}

.box-sidebar:last-child {
  border-bottom: none;
}

.header-box {
  position: relative;
  margin-bottom: 19px;
}

.header-box .search .btn-toggle-search {
  color: #63ba16;
}

.header-box .search .search-form {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #63ba16;
  height: 40px;
  padding: 2px;
  z-index: 2;
  display: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.header-box .search .search-form .form-control {
  height: 36px;
}

.header-box .search .search-form button {
  height: 40px;
}

.header-box .search .search-form.show {
  display: block;
}

.list-check-filter-job {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-check-filter-job li:not(:last-child) {
  margin-bottom: 4px;
}

.list-check-filter-job label {
  color: #9f9f9f;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label {
  color: #383838;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #63ba16;
  border-color: #63ba16;
}

.scroller {
  max-height: 300px;
}

.scroller .mCSB_container_wrapper {
  margin-right: 10px;
}

.scroller .mCSB_scrollTools {
  width: 4px;
}

.scroller .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  box-shadow: none;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.scroller .mCSB_container_wrapper > .mCSB_container {
  padding-right: 0;
}

.scroller .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #63ba16;
}

.scroller .mCS-3d.mCSB_scrollTools .mCSB_draggerRail, .scroller .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.scroller .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .scroller .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
}

.salary-range #amount {
  border: none;
  font-size: 1em;
  color: #9f9f9f;
  font-weight: 500;
  padding: 0;
  margin-bottom: 14px;
}

.salary-range #amount:focus {
  outline: none;
}

.salary-range .ui-widget.ui-widget-content {
  border: none;
  background-color: #dddddd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  height: 4px;
}

.salary-range .ui-slider-horizontal .ui-slider-range {
  background-color: #63ba16;
}

.salary-range .ui-state-default, .salary-range .ui-widget-content .ui-state-default, .salary-range .ui-widget-header .ui-state-default, .salary-range .ui-button, .salary-range html .ui-button.ui-state-disabled:hover, .salary-range html .ui-button.ui-state-disabled:active {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  background-color: #63ba16;
  -webkit-box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  -moz-box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  -ms-box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  -o-box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  box-shadow: 0 0 10px rgba(99, 186, 22, 0.6);
  top: -7px;
}

.sidebar-admin {
  background-color: #ffffff;
  margin-top: -42px;
  padding: 30px 0;
}

@media (max-width: 1023px) {
  .sidebar-admin {
    position: fixed;
    top: 0;
    left: -320px;
    z-index: 99;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    width: 310px;
    margin-top: 0;
  }
  .sidebar-admin.show {
    left: 0;
  }
}

.toggle-sidebar-admin {
  background-color: #63ba16;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  color: #ffffff;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 99;
  display: inline-block;
  border: none;
  font-size: 1.25em;
}

@media (min-width: 1024px) {
  .toggle-sidebar-admin {
    display: none;
  }
}

.toggle-sidebar-admin i {
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.toggle-sidebar-admin.active {
  left: 310px;
}

.toggle-sidebar-admin.active i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.header-user {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  color: #9f9f9f;
  padding: 0 30px 30px;
}

.header-user .avatar {
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  max-width: 60px;
}

.header-user .avatar img {
  max-height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.header-user .info-user {
  -ms-flex: 0 0 calc(100% - 60px);
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
  padding-left: 20px;
}

.header-user .info-user h3 {
  font-weight: 600;
  font-size: 1.125em;
  color: #383838;
  margin-bottom: 0;
}

.header-user .info-user h3 a {
  color: #383838;
}

.header-user .info-user h3 a:hover {
  color: #63ba16;
}

.header-user .info-user p {
  margin-bottom: 0;
}

.nav-sidebar-admin {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-sidebar-admin li a {
  color: #9f9f9f;
  display: block;
  padding: 10px 30px;
}

.nav-sidebar-admin li a:hover, .nav-sidebar-admin li a:focus {
  color: #63ba16;
  background-color: #fcfcfc;
}

.nav-sidebar-admin li a i {
  margin-right: 8px;
}

.nav-sidebar-admin li.active a {
  color: #63ba16;
  background-color: #fcfcfc;
}

.nav-sidebar-admin li .badge {
  margin-left: 8px;
}

.badge {
  font-weight: 600;
  font-size: 0.75em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.badge-green {
  background-color: #63ba16;
  color: #ffffff;
}

.list-categories {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-categories li {
  color: #9f9f9f;
}

.list-categories li:not(:last-child) {
  margin-bottom: 4px;
}

.list-categories li.active a {
  color: #63ba16;
}

.list-categories li a {
  color: inherit;
}

.list-categories li a:hover {
  color: #63ba16;
}

/*------------------------------------------------------------------
[7. Right column]
*/
@media (max-width: 991px) {
  .sidebar-right {
    margin-top: 16px;
  }
}

.sidebar-right .search-form {
  margin-bottom: 30px;
}

.sidebar-right .sidebar-right-group {
  background-color: #ffffff;
  border-radius: 5px;
}

.list-news-thumb {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-news-thumb li {
  overflow: hidden;
}

.list-news-thumb li:not(:last-child) {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .list-news-thumb li:not(:last-child) {
    margin-bottom: 25px;
  }
}

.list-news-thumb li .thumb {
  float: left;
  margin-right: 15px;
  width: 67px;
}

.list-news-thumb li .thumb img {
  display: block;
  border-radius: 5px;
}

.list-news-thumb li .content {
  width: calc(100% - 67px - 15px);
  float: left;
}

.list-news-thumb li .meta {
  color: #9f9f9f;
  line-height: 18px;
}

.list-news-title {
  margin-top: -5px;
  margin-bottom: 8px;
  font-size: 1em;
  line-height: 1.4;
  font-weight: 600;
}

.list-news-title a {
  color: inherit;
}

.list-news-title a:hover {
  color: #63ba16;
}

.tags a {
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 3px;
  transition: all .3s;
  margin: 0 5px 5px 0;
  padding: 4px 10px;
  color: #9f9f9f;
  font-weight: 600;
}

.tags a:hover {
  background-color: #63ba16;
  color: #ffffff;
}

.job-detail-summary {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .job-detail-summary {
    padding: 30px;
  }
}

.job-detail-summary h3 {
  margin-bottom: 20px;
}

.job-detail-summary ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.job-detail-summary ul li {
  color: #9f9f9f;
}

.job-detail-summary ul li:not(:last-child) {
  margin-bottom: 4px;
}

.job-detail-summary ul li span {
  color: #383838;
  font-weight: 600;
}

.job-detail-summary .btn {
  margin-top: 36px;
}

.side-right-map {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .side-right-map {
    padding: 30px;
  }
}

.side-right-map #map_canvas {
  height: 300px;
}

#map-right {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.side-right-social {
  padding: 30px 30px 20px;
}

.side-right-social ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.side-right-social ul li {
  margin-bottom: 10px;
}

.side-right-social ul li:not(:last-child) {
  margin-right: 10px;
}

.side-right-social ul li a {
  display: inline-block;
  background-color: #f1f1f1;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  color: #9f9f9f;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.side-right-social ul li a i {
  vertical-align: middle;
}

.side-right-social ul li a:hover, .side-right-social ul li a:focus {
  background-color: #63ba16;
  color: #ffffff;
}

/*------------------------------------------------------------------
[8. Pagination]
*/
.in-pagination {
  padding-top: 20px;
}

.in-pagination .pagination {
  margin-bottom: 0;
}

.in-pagination .page-link {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 1.625;
  color: #9f9f9f;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 10px;
  border: none;
}

.in-pagination .page-link:hover {
  z-index: 2;
  color: #ffffff;
  background-color: #383838;
}

@media only screen and (min-width: 768px) {
  .in-pagination .page-link {
    padding: 9px 17px;
  }
}

.in-pagination .page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #63ba16;
}

.pagination-page {
  padding: 13px 30px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .pagination-page {
    -ms-flex-pack: space-between;
    justify-content: space-between;
  }
}

.pagination-page .dropdown-custom {
  margin-bottom: 16px;
}

.pagination-page .page-link {
  padding: 0 10px;
  color: #9f9f9f;
  border: none;
}

.pagination-page .page-link:hover {
  background-color: transparent;
  color: #63ba16;
}

.pagination-page .page-item.active .page-link {
  background-color: transparent;
  color: #63ba16;
}

.pagination-page .page-item:not(.disabled).page-prev .page-link, .pagination-page .page-item:not(.disabled).page-next .page-link {
  font-weight: 600;
  color: #383838;
}

.blog-style-1 .pagination {
  justify-content: center;
}

/*------------------------------------------------------------------
[9. Job List]
*/
.content-main-right {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.header-list-job {
  border-bottom: 1px solid #e4e4e4;
  padding: 14px 20px 13px;
}

@media only screen and (min-width: 768px) {
  .header-list-job {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.header-list-job h4 {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 0;
}

.dropdown-custom span {
  color: #9f9f9f;
}

.dropdown-custom button {
  font-weight: 600;
}

.dropdown-custom .dropdown-menu {
  padding: 0;
}

.dropdown-custom .dropdown-item {
  font-size: 0.875em;
  padding: 6px 15px;
}

.dropdown-custom .dropdown-item:hover, .dropdown-custom .dropdown-item:focus {
  background-color: transparent;
  color: #63ba16;
}

.job-item {
  border-bottom: 1px solid #e4e4e4;
  color: #9f9f9f;
  padding: 20px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .job-item {
    padding: 30px 30px 25px;
  }
}

@media only screen and (min-width: 768px) {
  .job-item .col-md-2 {
    -ms-flex: 0 0 115px;
    flex: 0 0 115px;
    max-width: 115px;
  }
}

@media only screen and (min-width: 768px) {
  .job-item .col-md-10 {
    -ms-flex: 0 0 calc(100% - 115px);
    flex: 0 0 calc(100% - 115px);
    max-width: calc(100% - 115px);
  }
}

@media (max-width: 767px) {
  .job-item {
    position: relative;
  }
  .job-item .job-info {
    position: static;
  }
}

.job-item:hover {
  background-color: #fcfcfc;
}

.job-item:hover .title-job {
  color: #63ba16;
}

.job-item:hover .title-job a {
  color: #63ba16;
}

.job-item:hover .company-name {
  color: #383838;
}

.job-item:hover .add-favourites {
  background-color: #ffb13c;
  color: #ffffff;
}

.job-item:hover .add-favourites i:before {
  content: "\f005";
}

@media only screen and (min-width: 768px) {
  .job-item .text {
    position: relative;
    padding-right: 52px;
  }
}

.job-item .add-favourites {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media only screen and (min-width: 768px) {
  .job-item .add-favourites {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

.title-job {
  font-size: 1.125em;
  color: #383838;
  font-weight: 600;
  margin-bottom: 8px;
}

.title-job a {
  color: #383838;
}

.title-job a:hover, .title-job a:focus {
  color: #63ba16;
}

.date-job {
  padding-bottom: 3px;
}

.date-job .fa-check-circle {
  color: #63ba16;
  margin-right: 4px;
}

.meta-job {
  color: #9f9f9f;
}

.meta-job span {
  display: inline-block;
  margin-top: 3px;
}

.meta-job span:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (min-width: 768px) {
  .meta-job span:not(:last-child) {
    margin-right: 24px;
  }
}

.meta-job span i {
  margin-right: 6px;
}

.add-favourites {
  border: none;
  background-color: #f1f1f1;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  color: #9f9f9f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.add-favourites:hover, .add-favourites:focus {
  background-color: #ffb13c;
  color: #ffffff;
}

.add-favourites:hover i:before, .add-favourites:focus i:before {
  content: "\f005";
}

/*----------- job list map -----------*/
.content-job-list-map {
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .content-job-list-map {
    height: calc(100vh - 142px);
    overflow-y: auto;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 1200px) {
  .content-job-list-map .container {
    margin-left: 0;
    margin-right: 0;
    max-width: calc(100% - 450px);
  }
}

@media (min-width: 1920px) {
  .content-job-list-map .container {
    max-width: 1140px;
  }
}

.content-job-list-map .map-small {
  margin-bottom: 30px;
  height: 450px;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) {
  .content-job-list-map .map-small {
    display: none;
  }
}

.content-job-list-map .map {
  margin-bottom: 30px;
  overflow: hidden;
  max-height: 350px;
}

@media only screen and (min-width: 992px) {
  .content-job-list-map .map {
    max-height: 550px;
  }
}

@media only screen and (min-width: 1200px) {
  .content-job-list-map .map {
    margin-bottom: 0;
    max-height: unset;
    min-width: 450px;
    width: calc(100% - 1155px);
    background-color: #ebebeb;
    position: fixed;
    display: block;
    top: 100px;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }
  .content-job-list-map .map:after {
    top: 100%;
    left: 0;
    right: 0;
    height: 500px;
    background-color: #ebebeb;
    position: absolute;
    content: "";
  }
  .content-job-list-map .map .container {
    padding: 0;
  }
  .content-job-list-map .map img {
    height: 100%;
  }
}

@media (min-width: 1920px) {
  .content-job-list-map .map {
    min-width: unset;
  }
}

.browse-job-list-map .wrapper {
  overflow: hidden;
}

@media (min-width: 1920px) {
  .browse-job-list-map .section-heading {
    display: none;
  }
}

@media (min-width: 1920px) {
  .browse-job-list-map.collapse-filter .side-left-toggle {
    display: none;
  }
  .browse-job-list-map.collapse-filter .side-right-toggle {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .browse-job-list-map.collapse-filter .content-job-list-map .map {
    -ms-flex: 0 0 calc(100% - 790px);
    flex: 0 0 calc(100% - 790px);
    max-width: calc(100% - 790px);
    margin-left: -350px;
  }
}

.hide-filter {
  font-size: 1.125em;
  font-weight: 600;
  padding-left: 60px;
  margin-bottom: 20px;
  display: none;
}

@media (min-width: 1920px) {
  .hide-filter {
    display: block;
  }
}

.hide-filter .custom-control-label:before {
  height: 14px;
  width: 44px;
  background-color: #e6e6e6;
  left: -60px;
  border: none;
  top: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.hide-filter .custom-control-label:after {
  width: 22px;
  height: 22px;
  border: 1px solid #ffffff;
  background-color: #63ba16;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  top: 2px;
}

.hide-filter .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #e6e6e6;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.hide-filter .custom-control-input:checked ~ .custom-control-label:after {
  border: 1px solid #ffffff;
  background-color: #63ba16;
  right: 0;
  left: -60px;
  transform: inherit;
}

/*------------------------------------------------------------------
[10. Employer List]
*/
.filter-masonry {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) {
  .filter-masonry {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.filter-masonry .alpha {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 0 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1200px) {
  .filter-masonry .alpha {
    -ms-flex: 0 0 calc(100% - 140px);
    flex: 0 0 calc(100% - 140px);
    max-width: calc(100% - 140px);
    margin-bottom: 0;
    -ms-flex-pack: space-between;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .filter-masonry .all {
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    max-width: 140px;
    padding-left: 10px;
  }
}

.filter-masonry .all .btn {
  min-width: 180px;
}

@media only screen and (min-width: 1200px) {
  .filter-masonry .all .btn {
    display: block;
    width: 100%;
  }
}

.filter-masonry button.filter {
  font-weight: 600;
  color: #9f9f9f;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  text-transform: uppercase;
}

.filter-masonry button.filter.is-checked {
  color: #383838;
}

@media only screen and (min-width: 1200px) {
  .job-item.employer .col-xl-4 {
    -ms-flex: 0 0 204px;
    flex: 0 0 204px;
    max-width: 204px;
  }
}

@media only screen and (min-width: 1200px) {
  .job-item.employer .col-xl-8 {
    -ms-flex: 0 0 calc(100% - 204px);
    flex: 0 0 calc(100% - 204px);
    max-width: calc(100% - 204px);
  }
}

@media only screen and (min-width: 1200px) {
  .job-item.employer .btn-light {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }
}

.job-item.employer .number-position {
  margin-top: 10px;
}

@media only screen and (min-width: 1200px) {
  .job-item.employer .number-position {
    margin-top: 0;
  }
}

.job-item.employer:hover .btn-light {
  color: #ffffff;
  background-color: #63ba16;
  border-color: #63ba16;
}

.employer-item .employer-item-inner {
  background-color: #ffffff;
  padding: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.employer-item h3 {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.employer-item ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.employer-item ul li:not(:last-child) {
  margin-bottom: 4px;
}

.employer-item ul li a {
  color: #9f9f9f;
}

.employer-item ul li a:hover, .employer-item ul li a:focus {
  color: #63ba16;
}

/*----------- Employer Detail -----------*/
.item-detail-special {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .item-detail-special {
    padding: 33px 0;
  }
}

.item-detail-special .img, .item-detail-special .text {
  position: relative;
  min-height: 1px;
}

.item-detail-special .img {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .item-detail-special .img {
    -ms-flex: 0 0 160px;
    flex: 0 0 160px;
    max-width: 160px;
  }
}

.item-detail-special .text {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .item-detail-special .text {
    -ms-flex: 0 0 calc(100% - 160px);
    flex: 0 0 calc(100% - 160px);
    max-width: calc(100% - 160px);
  }
}

.item-detail-special .text .btn-feature {
  padding-right: 10px;
}

@media only screen and (min-width: 992px) {
  .item-detail-special .text .btn-feature {
    padding-right: 30px;
    text-align: right;
    margin-top: 0;
  }
}

.item-detail-special .btn-add-favourites {
  padding-left: 12px;
  padding-right: 12px;
}

.item-detail-special .btn {
  margin-top: 4px;
  margin-bottom: 4px;
}

.item-detail-special .btn + .btn {
  margin-left: 4px;
}

.date-style2 {
  color: #9f9f9f;
  padding-bottom: 3px;
}

.date-style2 span:not(:last-child) {
  margin-right: 22px;
}

.date-style2 i {
  margin-right: 4px;
}

.date-style2 .fa-check-circle {
  color: #63ba16;
}

.title-detail {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 9px;
}

.title-block {
  font-weight: 600;
  font-size: 1.125em;
  margin-bottom: 24px;
}

.simillar-jobs {
  margin-bottom: 30px;
}

.simillar-jobs .job-item:last-child {
  border-bottom: none;
}

.stars {
  display: inline-block;
}

.stars .point {
  display: inline-block;
  color: #ffb13c;
}

.star-rating {
  color: #ffb13c;
  display: inline-block;
}

.star-rating i {
  font-size: 1.125em;
}

.single-detail {
  color: #9f9f9f;
  margin-bottom: 27px;
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .single-detail {
    padding: 30px;
  }
}

.single-detail h3 {
  font-size: 1.125em;
  color: #383838;
  margin-bottom: 20px;
  font-weight: 600;
}

.single-detail hr {
  border-top-color: #e4e4e4;
  margin: 25px -20px;
}

@media only screen and (min-width: 768px) {
  .single-detail hr {
    margin-right: -30px;
    margin-left: -30px;
  }
}

.single-detail p {
  margin-bottom: 18px;
}

.single-detail p:last-child {
  margin-bottom: 0;
}

/*----------- review-wrap -----------*/
.reviews {
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
}

.reviews .review-item {
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .reviews .review-item {
    padding: 30px;
  }
}

.reviews .review-item:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.reviews .review-item .review-content {
  color: #9f9f9f;
  margin-top: 16px;
}

.reviews .review-item .review-title {
  font-size: 1.125em;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 5px;
}

.reviews .review-item .meta-text {
  color: #9f9f9f;
}

/*------------------------------------------------------------------
[11. Dashboard]
*/
/* 11.1 Dashboard */
.wrapper-admin {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sidebar-admin {
  -ms-flex: 0 0 310px;
  flex: 0 0 310px;
  max-width: 310px;
}

.main-content-admin {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}

@media (min-width: 1024px) {
  .main-content-admin {
    -ms-flex: 0 0 calc(100% - 310px);
    flex: 0 0 calc(100% - 310px);
    max-width: calc(100% - 310px);
    padding: 0 25px;
  }
}

.main-content-admin .container {
  padding-left: 0;
  padding-right: 0;
}

.main-content-admin .footer-site {
  margin-top: 0;
}

.main-content-admin .footer-bottom {
  padding-top: 14px;
}

.section-statics-dashboard .col-item {
  margin-bottom: 30px;
}

.static-dashboard-item {
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  min-height: 100%;
}

@media only screen and (min-width: 992px) {
  .static-dashboard-item {
    padding: 27px 30px 20px;
  }
}

.static-dashboard-item .static-icon {
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  max-width: 70px;
  margin-top: -4px;
}

.static-dashboard-item .static-icon i {
  font-size: 3.875em;
  line-height: 1;
  color: #63ba16;
}

.static-dashboard-item .text {
  -ms-flex: 0 0 calc(100% - 70px);
  flex: 0 0 calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 12px;
}

.static-dashboard-item .text h3 {
  color: #9f9f9f;
  font-size: 1.125em;
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 4px;
}

.static-dashboard-item .text .number {
  font-weight: 600;
  font-size: 1.875em;
}

.box-admin {
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) {
  .box-admin {
    margin-bottom: 30px;
  }
}

.header-box-admin {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 21px;
  padding: 15px 15px 0;
}

@media only screen and (min-width: 768px) {
  .header-box-admin {
    padding: 25px  30px 0;
  }
}

.header-box-admin h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.125em;
}

.header-box-admin .dropdown-custom button {
  color: #9f9f9f;
}

.section-notification-message .header-box-admin {
  padding-top: 16px;
  padding-left: 27px;
  margin-bottom: 16px;
}

.section-notification-message .header-box-admin h3 {
  font-size: 1em;
}

.body-box-admin {
  padding: 0 15px 15px;
}

@media only screen and (min-width: 768px) {
  .body-box-admin {
    padding: 0 30px 25px;
  }
}

.chart {
  padding-bottom: 8px;
}

.avatar-user {
  position: relative;
  width: 60px;
  height: 60px;
}

.avatar-user a {
  display: block;
}

.avatar-user img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.avatar-user .status {
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
}

.avatar-user .status.online {
  background-color: #63ba16;
}

.avatar-user .status.alway {
  background-color: #ffb13c;
}

.list-content-user {
  padding: 0;
  list-style: none;
  margin: 0;
}

.list-content-user li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 15px;
  color: #9f9f9f;
  border-top: 1px solid #e4e4e4;
}

@media only screen and (min-width: 768px) {
  .list-content-user li {
    padding: 20px 30px;
  }
}

.list-content-user li:hover {
  background-color: #fcfcfc;
}

.list-content-user li .thumb-avatar {
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  max-width: 60px;
}

.list-content-user li .text {
  -ms-flex: 0 0 calc(100% - 60px);
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
  padding-left: 20px;
}

@media only screen and (min-width: 768px) {
  .list-content-user li .text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
  }
  .list-content-user li .text .text-left {
    padding-right: 20px;
    -ms-flex: 0 0 calc(100% - 130px);
    flex: 0 0 calc(100% - 130px);
    max-width: calc(100% - 130px);
  }
  .list-content-user li .text .time {
    -ms-flex: 0 0 130px;
    flex: 0 0 130px;
    max-width: 130px;
    text-align: right;
  }
}

.list-content-user li h3 {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
}

.list-content-user li h3 a {
  color: #383838;
}

.list-content-user li h3 a:hover {
  color: #63ba16;
}

/* 11.1 Profile */
.upload-avatar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.upload-avatar .avatar {
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 80px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (min-width: 576px) {
  .upload-avatar .avatar {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    max-width: 100px;
  }
}

.upload-avatar .avatar img {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.upload-avatar .upload {
  -ms-flex: 0 0 calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
  padding-left: 15px;
}

@media only screen and (min-width: 576px) {
  .upload-avatar .upload {
    -ms-flex: 0 0 calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
    padding-left: 30px;
  }
}

.upload-avatar .upload .desc {
  color: #9f9f9f;
  margin-bottom: 15px;
}

.upload-avatar .upload .btn-upload {
  display: inline-block;
  position: relative;
}

.upload-avatar .upload .btn-upload input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.mb-30 {
  margin-bottom: 30px;
}

.edit-profile .header-box-admin {
  margin-bottom: 17px;
}

.edit-profile .upload-avatar {
  padding-top: 9px;
}

.edit-profile label {
  margin-bottom: 6px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .edit-profile label {
    margin-bottom: 16px;
  }
}

.edit-profile .form-group {
  margin-bottom: 24px;
}

.edit-profile .custom-control-label {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .edit-profile .form-control {
    padding-left: 20px;
  }
}

/* 11.3 Manage Jobs */
.tbl-job {
  margin-bottom: 0;
}

.tbl-job thead th {
  border-bottom-width: 1px;
  border-top: none;
  font-weight: 600;
  padding: 0 15px 15px;
}

.tbl-job thead th:first-child {
  width: 60%;
  padding-left: 30px;
}

.tbl-job thead th:last-child {
  padding-right: 30px;
}

.tbl-job tbody td {
  white-space: nowrap;
  vertical-align: middle;
  padding: 26px 15px;
}

.tbl-job tbody td:first-child {
  white-space: normal;
}

@media only screen and (min-width: 768px) {
  .tbl-job tbody td:first-child {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .tbl-job tbody td:last-child {
    padding-right: 30px;
  }
}

.salary-job {
  color: #9f9f9f;
}

.salary-job i {
  margin-right: 6px;
}

.my-job-item {
  color: #9f9f9f;
}

.my-job-item .title-job {
  font-weight: normal;
  margin-bottom: 5px;
}

.my-job-item .meta-job {
  margin-bottom: 6px;
}

.my-job-item .btn {
  padding: 0;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
}

.my-job-item .btn:not(:last-child) {
  margin-right: 6px;
}

.my-job-item .active {
  color: #63ba16;
}

.my-job-item .job-info {
  margin-bottom: 6px;
}

.my-job-item .job-info span {
  display: inline-block;
}

.my-job-item .job-info span:not(:last-child):after {
  content: "/";
  margin: 0 10px;
}

.my-job-item:hover {
  background-color: #fcfcfc;
}

.my-job-item:hover .title-job {
  color: #63ba16;
}

.my-job-item:hover .title-job a {
  color: #63ba16;
}

.my-job-item:hover .number-application {
  color: #63ba16;
}

.my-job-item:hover .btn {
  color: #ffffff;
}

.my-job-item:hover .btn-view, .my-job-item:hover .btn-new {
  background-color: #ffb13c;
  border-color: #ffb13c;
}

.my-job-item:hover .btn-edit {
  background-color: #63ba16;
  border-color: #63ba16;
}

.my-job-item:hover .btn-delete {
  background-color: #f82020;
  border-color: #f82020;
}

/* 11.4 Manage candidates */
.candidate-tile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.candidate-tile .img {
  -ms-flex: 0 0 85px;
  flex: 0 0 85px;
  max-width: 85px;
}

.candidate-tile .img img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.candidate-tile .text {
  -ms-flex: 0 0 calc(100% - 85px);
  flex: 0 0 calc(100% - 85px);
  max-width: calc(100% - 85px);
  padding-left: 15px;
}

@media only screen and (min-width: 576px) {
  .candidate-tile .text {
    padding-left: 30px;
  }
}

.rating-job {
  color: #ffb13c;
}

.rating-job i:not(:last-child) {
  margin-right: 5px;
}

.my-job-item.candidate .title-job {
  margin-bottom: 8px;
}

.my-job-item.candidate .date-job {
  padding-bottom: 7px;
}

.job-info-mobile {
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .job-info-mobile {
    padding-left: 115px;
    margin-top: 0;
  }
}

.job-info-mobile ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .job-info-mobile ul {
    margin-bottom: 0;
  }
}

.job-info-mobile ul li strong {
  font-weight: 600;
}

.job-item.bookmark:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 768px) {
  .job-item.bookmark .text {
    padding-right: 135px;
  }
}

.job-item.bookmark .group-button {
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .job-item.bookmark .group-button {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    margin-top: 0;
  }
}

.job-item.bookmark .btn {
  padding: 0;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.job-item.bookmark .btn:not(:last-child) {
  margin-right: 6px;
}

.job-item.bookmark .circle img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.job-item.bookmark:hover .btn {
  color: #ffffff;
}

.job-item.bookmark:hover .btn-view, .job-item.bookmark:hover .btn-new {
  background-color: #ffb13c;
  border-color: #ffb13c;
}

.job-item.bookmark:hover .btn-edit, .job-item.bookmark:hover .btn-email {
  background-color: #63ba16;
  border-color: #63ba16;
}

.job-item.bookmark:hover .btn-delete {
  background-color: #f82020;
  border-color: #f82020;
}

.my-skill label {
  display: block;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 6px;
}

.my-skill .btn {
  margin-top: 10px;
}

.my-skill .btn:not(:last-child) {
  margin-right: 10px;
}

.my-skill .btn i {
  margin-right: 5px;
}

/* 11.5 Employer Message */
.employer-messages .header-box-admin {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .employer-messages .header-box-admin {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  .employer-messages .body-box-admin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.header-box-right {
  padding-top: 7px;
  padding-bottom: 7px;
}

@media only screen and (min-width: 768px) {
  .header-box-right {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-box-right {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .header-box-right {
    -ms-flex: 0 0 305px;
    flex: 0 0 305px;
    max-width: 305px;
    padding-right: 30px;
  }
}

.conversationer {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .conversationer {
    padding-top: 30px;
    padding-bottom: 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1200px) {
  .conversationer {
    -ms-flex: 0 0 calc(100% - 305px);
    flex: 0 0 calc(100% - 305px);
    max-width: calc(100% - 305px);
    padding-left: 30px;
    border-left: 1px solid #e4e4e4;
  }
}

.search-friends {
  position: relative;
  padding-right: 55px;
  background-color: #f4f4f4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  height: 44px;
}

.search-friends .form-control {
  background-color: transparent;
  border-color: transparent;
  height: 100%;
}

.search-friends .form-control:focus {
  border-color: transparent;
}

.search-friends button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  text-align: center;
  width: 55px;
  color: #63ba16;
}

.chat {
  position: relative;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .chat {
    display: -ms-flexbox;
    display: flex;
  }
}

.contact-chat {
  background-color: #fcfcfc;
  overflow-x: hidden;
}

@media only screen and (min-width: 768px) {
  .contact-chat {
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
    max-width: 280px;
    border-right: 1px solid #e4e4e4;
  }
}

@media only screen and (min-width: 1200px) {
  .contact-chat {
    -ms-flex: 0 0 336px;
    flex: 0 0 336px;
    max-width: 336px;
  }
}

.msg-contacts {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.msg-contacts li .msg-contact-item {
  display: block;
  padding: 12px;
}

@media only screen and (min-width: 1200px) {
  .msg-contacts li .msg-contact-item {
    padding: 20px 30px;
  }
}

.msg-contacts li .msg-contact-item:hover {
  background-color: #ffffff;
}

.msg-contacts li .msg-contact-item::after {
  display: block;
  clear: both;
  content: '';
}

.msg-contacts li .avatar-user {
  float: left;
  width: 60px;
}

.msg-contacts li .text {
  float: left;
  width: calc(100% - 60px);
  padding-left: 12px;
}

@media only screen and (min-width: 768px) {
  .msg-contacts li .text {
    padding-left: 20px;
  }
}

.msg-contacts li .desc-short {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #9f9f9f;
}

.msg-contacts li .msg-contact-name {
  position: relative;
  padding-right: 25px;
  font-size: 1em;
  font-weight: 600;
  color: #383838;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.msg-contacts li .msg-contact-name .number-unread {
  position: absolute;
  right: 0;
  top: 2px;
  z-index: 2;
}

.msg-contacts li:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.msg-contacts li.active .msg-contact-item {
  background-color: #ffffff;
}

.content-chat {
  color: #9f9f9f;
  display: none;
}

@media only screen and (min-width: 768px) {
  .content-chat {
    -ms-flex: 0 0 calc(100% - 280px);
    flex: 0 0 calc(100% - 280px);
    max-width: calc(100% - 280px);
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .content-chat {
    -ms-flex: 0 0 calc(100% - 336px);
    flex: 0 0 calc(100% - 336px);
    max-width: calc(100% - 336px);
  }
}

.content-chat .list {
  padding: 15px;
}

@media only screen and (min-width: 1200px) {
  .content-chat .list {
    padding: 30px;
  }
}

.chat-item {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .chat-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

.chat-item .avatar {
  display: none;
}

@media only screen and (min-width: 768px) {
  .chat-item .avatar {
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    max-width: 60px;
    display: block;
  }
}

.chat-item .avatar img {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) {
  .chat-item .text {
    -ms-flex: 0 0 calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
    max-width: calc(100% - 60px);
  }
}

.chat-item .text .time {
  text-transform: uppercase;
  color: #9f9f9f;
  font-size: 1em;
}

@media only screen and (min-width: 768px) {
  .chat-item .text .time {
    margin-bottom: 7px;
  }
}

.chat-item .text .content {
  display: inline-block;
  padding: 7px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) {
  .chat-item .text .content {
    padding: 15px 20px;
  }
}

.chat-item.green .text {
  padding-left: 20px;
}

.chat-item.green .text .content {
  background-color: #63ba16;
  color: #ffffff;
}

.chat-item.gray {
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

.chat-item.gray .avatar {
  -ms-flex-order: 12;
  order: 12;
}

.chat-item.gray .text {
  padding-right: 20px;
  text-align: right;
}

.chat-item.gray .text .content {
  background-color: #f4f4f4;
  color: #9f9f9f;
}

.user-main {
  display: -ms-flexbox;
  display: flex;
}

.user-main .img {
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  max-width: 60px;
  margin-right: 20px;
}

.user-main .img img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.user-main .text {
  -ms-flex: 0 0 calc(100% - 80px);
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}

.user-main .text h4 {
  font-weight: 600;
  font-size: 1.125em;
  margin-bottom: 0;
}

.user-main .text .user-status {
  color: #9f9f9f;
}

.user-main .text .status {
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #63ba16;
  vertical-align: -1px;
}

.user-main .text .status.online {
  background-color: #63ba16;
}

.user-main .text .status.alway {
  background-color: #ffb13c;
}

.user-main .text .status.invisible-cs {
  border: 2px solid #ddd;
  background-color: #ffffff;
}

.back-view {
  display: none;
}

@media (max-width: 767px) {
  .conversation-mb .conversationer {
    padding-left: 35px;
  }
  .conversation-mb .user-main .img {
    display: none;
  }
  .conversation-mb .back-view {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    left: -5px;
    color: #383838;
    width: 30px;
    cursor: pointer;
  }
  .conversation-mb .back-view i {
    font-size: 1.25em;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .conversation-mb .content-chat {
    display: block;
  }
  .conversation-mb .contact-chat {
    display: none;
  }
  .conversation-mb .header-box-admin {
    display: block !important;
  }
  .conversation-mb .header-box-right {
    display: none;
  }
}

@media (max-width: 767px) {
  .delete-conversation {
    margin-top: 4px;
  }
}

.delete-conversation button {
  color: #f82020;
}

.delete-conversation button i {
  margin-right: 4px;
}

.time-line {
  position: relative;
  text-align: center;
  margin: 23px 0 25px;
}

.time-line:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
}

.time-line span {
  display: inline-block;
  padding: 0 20px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

.send-message {
  padding: 15px;
  border-top: 1px solid #e4e4e4;
}

@media only screen and (min-width: 768px) {
  .send-message {
    padding: 30px;
  }
}

.send-message .inner {
  position: relative;
  padding-right: 50px;
}

@media only screen and (min-width: 1200px) {
  .send-message .inner {
    padding-right: 190px;
  }
}

.send-message .form-control {
  height: 54px;
  border: none;
  padding-left: 0;
}

.send-message button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  height: 54px;
  line-height: 54px;
  width: 50px;
  padding: 0;
  text-align: center;
}

.send-message button span {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .send-message button {
    width: 190px;
  }
  .send-message button span {
    display: inline-block;
  }
}

@media (max-width: 1199px) {
  .send-message button {
    background-color: transparent;
    color: #63ba16;
    border-color: transparent;
  }
  .send-message button:hover, .send-message button:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    color: #63ba16;
  }
  .send-message button:active {
    color: #63ba16;
  }
}

/* 11.6 Employer Transactions */
.my-transactions .header-box-admin {
  border-bottom: none;
  margin-bottom: 0;
}

.transaction-item {
  color: #9f9f9f;
}

.transaction-item .job-title {
  color: #383838;
}

.transaction-item:hover {
  background-color: #fcfcfc;
}

.transction-profile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-width: 230px;
}

.transction-profile .avatar {
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  max-width: 60px;
}

.transction-profile .avatar img {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.transction-profile .text {
  -ms-flex: 0 0 calc(100% - 60px);
  flex: 0 0 calc(100% - 60px);
  max-width: calc(100% - 60px);
  padding-left: 20px;
}

.transction-profile .text h4 {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 0;
  color: #383838;
}

.transction-profile .text h4 a {
  color: #383838;
}

.transction-profile .text h4 a:hover {
  color: #63ba16;
}

.transction-profile .text p {
  margin-bottom: 0;
}

.tbl-transactions {
  margin-bottom: 0;
}

.tbl-transactions thead th {
  white-space: nowrap;
  border-bottom-width: 1px;
  font-weight: 600;
  border-top: none;
  padding-bottom: 15px;
}

.tbl-transactions td, .tbl-transactions th {
  vertical-align: middle;
  border-color: #e4e4e4;
  padding: 15px 8px;
}

@media only screen and (min-width: 768px) {
  .tbl-transactions td, .tbl-transactions th {
    padding: 20px 15px;
  }
}

.tbl-transactions td:first-child, .tbl-transactions th:first-child {
  padding-left: 15px;
}

@media only screen and (min-width: 768px) {
  .tbl-transactions td:first-child, .tbl-transactions th:first-child {
    padding-left: 30px;
  }
}

.tbl-transactions td:last-child, .tbl-transactions th:last-child {
  padding-right: 15px;
}

@media only screen and (min-width: 768px) {
  .tbl-transactions td:last-child, .tbl-transactions th:last-child {
    padding-right: 30px;
  }
}

/* 11.6 Employer Transactions */
/* 11.6 Employer Transactions */
/*------------------------------------------------------------------
[12. Candidate]
*/
.candidate-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e4e4e4;
  position: relative;
  padding: 30px 0;
  color: #9f9f9f;
}

@media only screen and (min-width: 992px) {
  .candidate-item.hover:hover .title-job {
    color: #63ba16;
  }
  .candidate-item.hover:hover .title-job a {
    color: #63ba16;
  }
  .candidate-item.hover:hover .btn-light {
    background-color: #63ba16;
    border-color: #63ba16;
    color: #ffffff;
  }
}

.candidate-img {
  -ms-flex: 0 0 115px;
  flex: 0 0 115px;
  max-width: 115px;
}

@media only screen and (min-width: 1200px) {
  .candidate-img {
    -ms-flex: 0 0 145px;
    flex: 0 0 145px;
    max-width: 145px;
  }
}

.candidate-img img {
  border-radius: 50%;
  width: 85px;
  height: 85px;
  display: block;
  margin: 0 auto;
}

.candidate-content {
  -ms-flex: 0 0 calc(100% - 115px);
  flex: 0 0 calc(100% - 115px);
  max-width: calc(100% - 115px);
}

@media only screen and (min-width: 1200px) {
  .candidate-content {
    -ms-flex: 0 0 calc(100% - 145px);
    flex: 0 0 calc(100% - 145px);
    max-width: calc(100% - 145px);
  }
}

.candidate-content .candidate-button {
  padding-right: 15px;
  text-align: right;
}

@media (max-width: 767px) {
  .candidate-content .candidate-button {
    margin-top: 7px;
    text-align: left;
  }
}

.candidate-content .candidate-button .btn-add-favourites {
  background-color: #ffb13c;
  color: #ffffff;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
}

.item-list .item-img {
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  max-width: 50px;
}

.item-list .item-img img {
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 5px;
}

.item-list .item-body {
  -ms-flex: 0 0 calc(100% - 50px);
  flex: 0 0 calc(100% - 50px);
  max-width: calc(100% - 50px);
  padding-left: 20px;
}

@media only screen and (min-width: 768px) {
  .item-list .item-body {
    padding-left: 30px;
  }
}

.item-list .item-title {
  font-weight: 600;
  color: #383838;
}

.item-list .item-title .item-position {
  color: #63ba16;
}

.item-list:not(:last-child) {
  margin-bottom: 25px;
}

.process {
  margin-bottom: 25px;
}

.process .process-point {
  font-weight: 600;
  margin-bottom: 6px;
  color: #383838;
}

.process .process-in-category {
  color: #9f9f9f;
}

.process .process-bar {
  height: 4px;
  background-color: #e6e6e6;
  border-radius: 2px;
  position: relative;
}

.process .process-bar .process-bar-status {
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #63ba16;
  border-radius: 2px;
  display: inline-block;
}

/*------------------------------------------------------------------
[13. Blog]
*/
@media only screen and (min-width: 768px) {
  .single-item-list {
    padding-bottom: 20px;
  }
}

.card-info-grid {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.card-info-grid .meta {
  margin-bottom: 2px;
}

.card-info-grid .card-info-title {
  margin-bottom: 14px;
}

@media (max-width: 575px) {
  .card-info-grid .card-info-title {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .card-info-grid .card-info-text {
    display: none;
  }
}

.card-info-grid-img {
  position: relative;
  padding: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  max-width: 100px;
}

@media only screen and (min-width: 576px) {
  .card-info-grid-img {
    -ms-flex: 0 0 31.5068493151%;
    flex: 0 0 31.5068493151%;
    max-width: 31.5068493151%;
  }
}

.card-info-grid-img a {
  transition: all .3s;
}

.card-info-grid-img img {
  display: block;
  transition: all .3s;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .card-info-grid-img img {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.card-info-grid-body {
  flex: 1 1 0px;
  padding: 15px;
}

@media only screen and (min-width: 768px) {
  .card-info-grid-body {
    padding: 23px 30px 20px;
  }
}

.blog-post-media {
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
}

@media only screen and (min-width: 768px) {
  .blog-post-media {
    padding: 26px 30px 20px;
  }
}

.blog-post-media .blog-post-meta > span {
  display: inline-block;
  margin-bottom: 7px;
}

.blog-post-media .blog-post-meta > span:not(last-child) {
  margin-right: 30px;
}

.blog-post-media .blog-post-title {
  font-size: 1.5em;
  line-height: 1.416667;
  color: #383838;
  font-weight: 600;
  margin-bottom: 27px;
}

.blog-post-media .blog-post-media-body {
  color: #9f9f9f;
  margin-bottom: 31px;
}

.blog-post-media figure {
  margin-bottom: 30px;
}

.blog-post-media figure img {
  display: block;
  margin: 0;
}

.blog-post-media .blog-post-highlight {
  font-size: 1.125em;
  color: #383838;
  border-left: 4px solid #63ba16;
  padding: 3px 0 0 20px;
  margin-bottom: 26px;
  line-height: 1.6;
}

@media only screen and (min-width: 768px) {
  .blog-post-media .post-share {
    display: flex;
    justify-content: space-between;
  }
}

.blog-post-media .post-share label {
  color: #383838;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 5px;
}

.social-icon a {
  display: inline-block;
  margin: 0 10px;
  color: #9f9f9f;
}

.social-icon a:hover {
  color: #63ba16;
}

.post-paging {
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 26px;
  padding: 15px;
}

@media only screen and (min-width: 768px) {
  .post-paging {
    padding: 23px 30px;
  }
}

.post-paging .paging-prev {
  text-align: left;
}

.post-paging .paging-next {
  text-align: right;
}

.post-paging .paging-icon-link a {
  color: #9f9f9f;
  font-weight: 600;
}

.post-paging .paging-icon-link a:hover {
  color: #63ba16;
}

.post-paging .paging-text-link a {
  font-size: 1.125em;
  color: #383838;
  font-weight: 600;
}

.post-paging .paging-text-link a:hover {
  color: #63ba16;
}

.comments {
  margin-bottom: 25px;
}

.comments .comment-wrap {
  background-color: #ffffff;
  border-radius: 5px;
}

.comment-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.comment-list > li {
  padding: 15px;
}

.comment-list > li:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

@media only screen and (min-width: 768px) {
  .comment-list > li {
    padding: 30px 30px 25px;
  }
}

.comment-list .children {
  list-style: none;
  padding-left: 40px;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .comment-list .children {
    padding-left: 105px;
  }
}

.comment-list .children li {
  margin-top: 25px;
}

.comment-item {
  min-height: 120px;
}

.comment-item .comment-heading {
  min-height: 75px;
  margin-bottom: 10px;
}

.comment-item .thumb {
  width: 75px;
  text-align: center;
  position: relative;
  float: left;
}

.comment-item .thumb img {
  width: 75px;
  height: 75px;
  display: block;
  border-radius: 50%;
}

.comment-item .comment-info {
  padding-left: 90px;
}

@media only screen and (min-width: 768px) {
  .comment-item .comment-info {
    padding-left: 105px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .comment-item .comment-info-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .comment-item .comment-info-wrap .comment-info-left {
    flex: 1 1 0px;
  }
  .comment-item .comment-info-wrap .comment-reply {
    padding-left: 15px;
  }
  .comment-item .comment-info-wrap .comment-reply a {
    color: #63ba16;
  }
  .comment-item .comment-info-wrap .comment-reply a:hover {
    color: #383838;
  }
}

.comment-item .comment-info-wrap .comment-reply {
  margin-top: 5px;
}

.comment-item .comment-author {
  font-size: 1em;
  line-height: 1.625;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 3px;
}

.comment-item .meta {
  color: #9f9f9f;
}

.comment-item .comment-text {
  color: #9f9f9f;
}

@media only screen and (min-width: 768px) {
  .comment-item .comment-text {
    padding-left: 105px;
  }
}

.post-comment {
  margin-bottom: 25px;
}

.post-comment .comment-form {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;
}

@media only screen and (min-width: 768px) {
  .post-comment .comment-form {
    padding: 23px 30px;
  }
}

.forms .form-control {
  border-color: #e4e4e4;
}

.forms .form-control:focus {
  border-color: #63ba16;
}

.forms input.form-control,
.forms select.form-control {
  height: 44px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.forms .lbl {
  font-weight: 600;
  margin-bottom: 12px;
}

@media only screen and (min-width: 768px) {
  .forms .form-group {
    margin-bottom: 23px;
  }
}

/*------------------------------------------------------------------
[14. Form]
*/
.form-control {
  height: 44px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #63ba16;
}

textarea.form-control {
  height: 200px;
  resize: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label {
  color: #383838;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: transparent;
  border-color: transparent;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  font-weight: 900;
  content: "\f058";
  font-size: 1em;
  font-family: 'Font Awesome 5 Free';
  text-align: center;
  line-height: 1;
  color: #63ba16;
}

.sign-up {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .sign-up {
    padding-top: 153px;
    padding-bottom: 100px;
  }
}

.sign-up-header {
  margin-bottom: 25px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .sign-up-header {
    margin-bottom: 45px;
  }
}

.sign-up-header h2 {
  font-size: 1.75em;
  font-weight: 600;
  margin-bottom: 6px;
  line-height: 1.2;
}

.sign-up-header p {
  color: #9f9f9f;
  margin-bottom: 0;
}

.sign-up-header p a {
  color: #63ba16;
}

.form-sign-up .btn {
  height: 54px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.form-sign-up .account-type {
  margin-bottom: 20px;
}

.form-sign-up .account-type .btn {
  margin-bottom: 10px;
}

.form-sign-up .account-type .btn i {
  margin-right: 5px;
}

.form-sign-up .input-group-icons {
  position: relative;
  margin-bottom: 20px;
}

.form-sign-up .input-group-icons .prepend-icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 53px;
  border-right: 1px solid #e4e4e4;
  text-align: center;
  color: #9f9f9f;
}

.form-sign-up .input-group-icons .prepend-icon i {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  vertical-align: top;
}

.form-sign-up .input-group-icons .form-control {
  padding-left: 75px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.form-sign-up .input-group-icons .form-control:not(:focus) {
  border-color: #ffffff;
}

.form-sign-up .input-group-icons input.form-control,
.form-sign-up .input-group-icons select.form-control {
  height: 54px;
}

.form-control:focus ~ .prepend-icon {
  border-right-color: #63ba16 !important;
  color: #63ba16 !important;
}

.sign-up-other {
  margin-top: 23px;
}

.sign-up-other .text-or {
  text-align: center;
  position: relative;
  color: #9f9f9f;
  margin-bottom: 12px;
}

.sign-up-other .text-or::after, .sign-up-other .text-or::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  background-color: #e4e4e4;
  content: '';
  display: block;
  width: calc(50% - 30px);
}

.sign-up-other .text-or::after {
  right: 0;
}

.sign-up-other .text-or::before {
  left: 0;
}

.sign-in-social .sign-in-btn {
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  width: 100%;
  padding: 6px 15px;
  margin-top: 10px;
}

.sign-in-social .sign-in-btn i {
  margin-right: 5px;
}

.sign-in-btn-fb {
  background-color: #3b5998;
}

.sign-in-btn-fb:hover {
  background-color: #2d4373;
}

.sign-in-btn-google-p {
  background-color: #dd4b39;
}

.sign-in-btn-google-p:hover {
  background-color: #c23321;
}

.text-password {
  display: flex;
  justify-content: space-between;
  margin-top: -6px;
  margin-bottom: 6px;
}

.text-password input[type="checkbox"] {
  position: relative;
  top: 1px;
}

/*------------------------------------------------------------------
[15. FAQ]
*/
.content-box {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
}

@media only screen and (min-width: 768px) {
  .content-box {
    padding: 25px 30px;
  }
}

.content-box h4 {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 21px;
}

.content-box p {
  color: #9f9f9f;
  margin-bottom: 20px;
}

/*------------------------------------------------------------------
[16. Checkout]
*/
.shopping-cart-box:not(:last-child) {
  margin-bottom: 5px;
}

.shopping-cart-box + .shopping-cart-box {
  border-top: 1px solid #e4e4e4;
}

@media (max-width: 991px) {
  .shopping-cart-box .header-box-admin {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .shopping-cart-box .body-box-admin {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.shopping-cart-info {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
}

.package-item {
  padding-left: 10px;
  padding-right: 10px;
  min-height: 10px;
  position: relative;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.package-item a {
  padding: 15px;
  border: 1px solid #e4e4e4;
  display: block;
  border-radius: 5px;
  color: #9f9f9f;
  height: 100%;
}

@media only screen and (min-width: 992px) {
  .package-item a {
    padding: 26px 30px 23px;
  }
}

.package-item a .name {
  margin-bottom: 10px;
  font-weight: 600;
  position: relative;
  padding-left: 22px;
}

.package-item a .name::before {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  content: "\f111";
  font-size: 1em;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  text-align: center;
  line-height: 1;
}

.package-item a .price {
  margin-bottom: 0;
}

.package-item a.active, .package-item a:hover {
  border-color: #63ba16;
}

.package-item a.active .name, .package-item a:hover .name {
  color: #63ba16;
}

.package-item a.active .name::before, .package-item a:hover .name::before {
  font-weight: 900;
  content: "\f058";
}

.payment-method .choose-payment-method {
  margin-bottom: 25px;
}

.payment-method .choose-payment-method .payment-method-item {
  display: inline-block;
  margin-bottom: 5px;
}

.payment-method .choose-payment-method .payment-method-item:not(:last-child) {
  margin-right: 25px;
}

.payment-method .payment-method-content {
  margin-bottom: -15px;
}

@media only screen and (min-width: 768px) {
  .payment-method .payment-method-content .form-group {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .affix-top .header-box-admin {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .affix-top .body-box-admin {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.affix-top .fee {
  display: flex;
  justify-content: space-between;
}

.affix-top .fee span {
  color: #9f9f9f;
  padding-right: 10px;
  flex: 1 1 0px;
  font-size: 1.125em;
}

.affix-top .fee strong {
  font-weight: normal;
  color: #383838;
  text-align: right;
  min-height: 1px;
}

.affix-top .sub-total {
  border-top: 1px solid #e4e4e4;
  margin-top: 23px;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}

.affix-top .sub-total .text-label {
  font-size: 1.125em;
  color: #9f9f9f;
  flex: 1 1 0px;
}

.affix-top .sub-total .amount {
  color: #63ba16;
  font-weight: 600;
  font-size: 1.125em;
}

.affix-top .tick {
  margin-top: -7px;
  margin-bottom: 10px;
}

.affix-top .tick input {
  margin-right: 3px;
  position: relative;
  top: 2px;
}

/*------------------------------------------------------------------
[17. Error]
*/
.error-page-message {
  max-width: 870px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 65px;
  padding-bottom: 75px;
}

@media only screen and (min-width: 768px) {
  .error-page-message {
    padding-top: 130px;
    padding-bottom: 150px;
  }
}

.error-page-message .msg1 {
  font-size: 9.375em;
  color: #63ba16;
  line-height: 1;
  margin-bottom: 6px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .error-page-message .msg1 {
    font-size: 15em;
  }
}

.error-page-message .msg2 {
  font-size: 1.375em;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .error-page-message .msg2 {
    font-size: 1.75em;
  }
}

.error-page-message .msg3 {
  color: #9f9f9f;
  text-align: center;
  margin-bottom: 0;
}

.error-page-message .error-page-search {
  max-width: 542px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
}

.search-form-2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.search-form-2 .form-control {
  height: 54px;
  padding: 14px 20px;
  line-height: 24px;
  color: #9f9f9f;
  border-color: #ffffff;
  border-radius: 4px;
  flex: 1 1 0px;
  min-width: 0;
}

.search-form-2 .form-control:focus {
  border-color: #63ba16;
}

.search-form-2 button {
  background-color: #63ba16;
  color: #ffffff;
  height: 54px;
  border-radius: 4px;
  margin-left: 12px;
}

@media (max-width: 767px) {
  .search-form-2 {
    display: block;
    text-align: center;
  }
  .search-form-2 button {
    margin-top: 12px;
    margin-left: 0;
  }
}

/*------------------------------------------------------------------
[18. Contact]
*/
.map-contact .map-contact-canvas {
  height: 350px;
}

@media only screen and (min-width: 768px) {
  .map-contact .map-contact-canvas {
    height: 670px;
  }
}

.box-content {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .box-content {
    padding: 25px 30px;
    margin-bottom: 30px;
  }
}

.box-content .box-content-text {
  color: #9f9f9f;
  line-height: 1.8;
}

.box-content .box-content-text a {
  color: inherit;
}

.box-content .box-content-text a:hover {
  color: #63ba16;
}

.box-content .box-content-title {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 7px;
}

@media only screen and (min-width: 768px) {
  .box-content .box-content-title {
    margin-bottom: 17px;
  }
}

.box-content .box-content-title span {
  color: #63ba16;
}

/*------------------------------------------------------------------
[19. Footer]
*/
.footer-site {
  background-color: #383838;
  color: #9f9f9f;
  margin-top: 50px;
}

.footer-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 50px 0;
  color: #ffffff;
  margin-bottom: 46px;
}

.nav-footer {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media only screen and (min-width: 992px) {
  .nav-footer {
    text-align: right;
  }
}

.nav-footer li {
  display: inline-block;
}

.nav-footer li:not(:last-child) {
  margin-right: 28px;
}

.nav-footer li a {
  color: #ffffff;
}

.nav-footer li a:hover {
  color: #63ba16;
}

.footer-middle {
  color: #9f9f9f;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
}

.footer-middle a {
  color: #9f9f9f;
}

.footer-middle a:hover {
  color: #63ba16;
}

.title-footer {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 16px;
  color: #ffffff;
}

.newsletter {
  margin-bottom: 30px;
}

.newsletter .name {
  color: #ffffff;
}

.newsletter .name span {
  color: #63ba16;
}

.newsletter p {
  margin-bottom: 24px;
}

.newsletter .form {
  position: relative;
  padding-right: 54px;
}

.newsletter .form .form-control {
  background-color: #3f3f3f;
  border-color: #3f3f3f;
  color: #9f9f9f;
  height: 44px;
  padding-left: 20px;
}

.newsletter .form .form-control:focus {
  box-shadow: none;
  border-color: #63ba16;
}

.newsletter .form button {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  background-color: #63ba16;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.newsletter .form button:hover, .newsletter .form button:focus {
  color: #ffffff;
  background-color: #57a313;
}

.social-footer {
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
}

.social-footer li {
  display: inline-block;
}

.social-footer li:not(:last-child) {
  margin-right: 14px;
}

.social-footer li a {
  color: #9f9f9f;
}

.social-footer li a:hover, .social-footer li a:focus {
  color: #63ba16;
}

.links-footer {
  list-style: none;
  padding: 0;
  margin: 0 0 25px;
}

.links-footer li:not(:last-child) {
  margin-bottom: 4px;
}

.footer-bottom {
  padding: 44px 0 48px;
}

.language-footer button {
  color: #ffffff;
  margin-left: 3px;
}

.language-footer .dropdown-menu {
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.language-footer .dropdown-menu .dropdown-item {
  padding: 6px 15px;
  font-size: 14px;
}

.language-footer .dropdown-menu .dropdown-item:hover, .language-footer .dropdown-menu .dropdown-item:focus {
  background-color: #63ba16;
  color: #ffffff;
}

.footer-site-style2 {
  background-color: transparent;
  color: #9f9f9f;
}

.footer-site-style2 .text-white {
  color: #383838 !important;
  font-weight: 700;
}

.footer-site-style2 .language-footer button {
  color: #383838;
}

.footer-site-style2 .container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}

@media (min-width: 1920px) {
  .browse-job-list-map .footer-site-style2 .container {
    margin-left: 0;
    margin-right: 0;
  }
}
