/*------------------------------------------------------------------
[14. Form]
*/
.form-control{
	height: 44px;
	&:focus{
	  box-shadow: none;
	  border-color: $bg-basic-1;
	}
}
textarea.form-control {
	height: 200px;
	resize: none;
}
.custom-radio .custom-control-input:checked~.custom-control-label {
  color: $bg-basic-2;
}
.custom-radio .custom-control-input:checked~.custom-control-label:before {
  background-color: transparent;
  border-color: transparent;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after{
	background-image: none;
	font-weight: 900;
  content: "\f058";
	font-size: 1em;
	font-family: 'Font Awesome 5 Free';
	text-align: center;
	line-height: 1;
	color: $clr-1;
}
.sign-up{
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint(md){
    padding-top: 153px;
    padding-bottom: 100px;
  }
}
.sign-up-header{
  margin-bottom: 25px;
  text-align: center;
  @include breakpoint(md){
    margin-bottom: 45px;
  }
  h2{
    font-size: 1.75em;
    font-weight: 600;
    margin-bottom: 6px;
    line-height: 1.2;
  }
  p{
    color: $clr-2;
    margin-bottom: 0;
    a{
      color: $clr-1;
    }
  }
}
.form-sign-up{
  .btn{
    height: 54px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .account-type{
    margin-bottom: 20px;
    .btn{
      margin-bottom: 10px;
      i{
        margin-right: 5px;
      }
    }
  }
  .input-group-icons{
    position: relative;
    margin-bottom: 20px;
    .prepend-icon{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 53px;
      border-right: 1px solid #e4e4e4;
      text-align: center;
      color: $clr-2;
      i{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1;
        vertical-align: top;
      }
    }
    .form-control{
      padding-left: 75px;
      padding-top: 11px;
      padding-bottom: 11px;
      &:not(:focus){
        border-color: $clr-white;
      }
    }
    input.form-control, 
    select.form-control{
      height: 54px;
    }
  }
}
.form-control:focus~.prepend-icon{
  border-right-color: $bg-basic-1 !important;
  color: $clr-1 !important;
}
.sign-up-other{
  margin-top: 23px;
  .text-or{
    text-align: center;
    position: relative;
    color: $clr-2;
    margin-bottom: 12px;
    &::after, &::before{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #e4e4e4;
      content: '';
      display: block;
      width: calc(50% - 30px);
    }
    &::after{
      right: 0;
    }
    &::before{
      left: 0;
    }
  }
}
.sign-in-social {
  .sign-in-btn{
    border-radius: 5px;
    text-align: center;
    color: $clr-white;
    display: inline-block;
    width: 100%;
    padding: 6px 15px;
    margin-top: 10px;
    i{
      margin-right: 5px;
    }
  }
}
.sign-in-btn-fb{
  background-color: #3b5998;
  &:hover{
    background-color: darken(#3b5998, 10%);
  }
}
.sign-in-btn-google-p{
  background-color: #dd4b39;
  &:hover{
    background-color: darken(#dd4b39, 10%);
  }
}
.text-password{
  display: flex;
  justify-content: space-between;
  margin-top: -6px;
  margin-bottom: 6px;
  input[type="checkbox"]{
    position: relative;
    top: 1px;
  }
}