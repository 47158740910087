/*------------------------------------------------------------------
[15. FAQ]
*/
.content-box{
	background-color: $clr-white;
	padding: 15px;
	border-radius: 5px;
	@include breakpoint(md){
		padding: 25px 30px;
	}
	h4{
		font-size: 1.125em;
		font-weight: 600;
		margin-bottom: 21px;
	}
	p {
		color: $clr-2;
		margin-bottom: 20px;
	}
}