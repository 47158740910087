/*------------------------------------------------------------------
[13. Blog]
*/
.single-item-list{  
  @include breakpoint(md) {
    padding-bottom: 20px;
  }
}
.card-info-grid{
	margin-bottom: 30px;
	background-color: $clr-white;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	.meta{
	  margin-bottom: 2px;
	}
	.card-info-title{
    margin-bottom: 14px;
    @media (max-width: 575px){
      margin-bottom: 0;
    }
  }
  .card-info-text{
    @media (max-width: 575px){
      display: none;
    }
  }
}
.card-info-grid-img{
  position: relative;
  padding: 0;
  @include flexWidth(100px);
  @include breakpoint(sm){
    @include flexWidth(31.5068493151%);
  }
  a{
    transition: all .3s;
  }
  img{
    display: block;
    transition: all .3s;
    max-width: 100%;
    @include breakpoint(sm){
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}
.card-info-grid-body{
  flex: 1 1 0px;
  padding: 15px;
  @include breakpoint(md){
    padding: 23px 30px 20px;
  }
}
.blog-post-media{
  background-color: $clr-white;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 15px;
  @include breakpoint(md){
    padding: 26px 30px 20px;
  }
  .blog-post-meta{
    &>span{
      display: inline-block;
      margin-bottom: 7px;
      &:not(last-child){
        margin-right: 30px;
      }
    }
  }
  .blog-post-title{
    font-size: 1.5em;
    line-height: 1.416667;
    color: #383838;
    font-weight: 600;
    margin-bottom: 27px;
  }
  .blog-post-media-body{
    color: $clr-2;
    margin-bottom: 31px;
  }
  figure{
    margin-bottom: 30px;
    img{
      display: block;
      margin: 0;
    }
  }
  .blog-post-highlight{
    font-size: 1.125em;
    color: #383838;
    border-left: 4px solid $bg-basic-1;
    padding: 3px 0 0 20px;
    margin-bottom: 26px;
    line-height: 1.6;
  }
  .post-share{
    @include breakpoint(md){
      display: flex;
      justify-content: space-between;
    }
    label{
      color: #383838;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 5px;
    }
  }
}
.social-icon{
  a{
    display: inline-block;
    margin: 0 10px;
    color: $clr-2;
    &:hover{
      color: $clr-1;
    }
  }
}
.post-paging{
  background-color: $clr-white;
  border-radius: 5px;
  margin-bottom: 26px;
  padding: 15px;
  @include breakpoint(md){
    padding: 23px 30px;
  }
  .paging-prev{
    text-align: left;
  }
  .paging-next{
    text-align: right;
  }
  .paging-icon-link a{
    color: $clr-2;
    font-weight: 600;
    &:hover{
      color: $clr-1;
    }
  }
  .paging-text-link a{
    font-size: 1.125em;
    color: #383838;
    font-weight: 600;
    &:hover{
      color: $clr-1;
    }
  }
}
.comments{
  margin-bottom: 25px;
  .comment-wrap{
    background-color: $clr-white;
    border-radius: 5px;
  }

}
.comment-list{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  &>li{
    padding: 15px;
    &:not(:last-child){
      border-bottom: 1px solid #e4e4e4;
    }
    @include breakpoint(md){
      padding: 30px 30px 25px;
    }
  }
  .children{
    list-style: none;
    padding-left: 40px;
    margin-bottom: 0;
    @include breakpoint(md){
      padding-left: 105px;
    }
    li{
      margin-top: 25px;
    }
  }
}
.comment-item{
  min-height: 120px;
  .comment-heading{
    min-height: 75px;
    margin-bottom: 10px;
  }
  .thumb{
    width: 75px;
    text-align: center;
    position: relative;
    float: left;
    img{
      width: 75px;
      height: 75px;
      display: block;
      border-radius: 50%;
    }
  }
  .comment-info{
    padding-left: 90px;
    @include breakpoint(md){
      padding-left: 105px;
      padding-top: 10px;
    }
  }
  .comment-info-wrap{
    @include breakpoint(md){
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .comment-info-left{
        flex: 1 1 0px;
      }
      .comment-reply{
        padding-left: 15px;
        a{
          color: $clr-1;
          &:hover{
            color: #383838;
          }
        }
      }
    }
    .comment-reply{
      margin-top: 5px;
    }
  }
  .comment-author{
    font-size: 1em;
    line-height: $line-height-base;
    font-weight: 600;
    margin-bottom: 3px;
    margin-top: 3px;
  }
  .meta{
    color: $clr-2;
  }
  .comment-text{
    color: $clr-2;
    @include breakpoint(md){
      padding-left: 105px;
    }
  }
}
.post-comment{
  margin-bottom: 25px;
  .comment-form{
    background-color: $clr-white;
    border-radius: 5px;
    padding: 15px;
    @include breakpoint(md){
      padding: 23px 30px;
    }
  }
}
.forms{
  .form-control{
    border-color: #e4e4e4;
    &:focus{
      border-color: $bg-basic-1;
    }
  }
  input.form-control,
  select.form-control{
    height: 44px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .lbl{
    font-weight: 600;
    margin-bottom: 12px;
  }
  .form-group{
    @include breakpoint(md){
      margin-bottom: 23px;
    }
  }
}