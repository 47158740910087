// Bootstrap overrides

//grid breakpoints
$grid-breakpoints    : (
  xs                 : 0,
  sm                 : 576px,
  md                 : 768px,
  lg                 : 992px,
  xl                 : 1200px,
); //to get value using map-get($grid-breakpoints, "lg")
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm                 : 540px,
  md                 : 720px,
  lg                 : 960px,
  xl                 : 1140px,
);

$font-size-base-mb          : 15px !default;
$font-size-base             : 16px !default;
$line-height-base           : 1.625 !default;
$headings-line-height       : 1.5 !default;

// color
$clr-black: #000000;
$clr-white: #ffffff;
$clr-basic-text: #383838;

$clr-link: #63ba16;
$clr-link-hover: #383838;

$clr-1: #63ba16; // color light green
$clr-2: #9f9f9f; // color light grey
$clr-3: #ffb13c; // color orange
$clr-4: #f82020; // color red

// bg color
$bg-basic-1: #63ba16;
$bg-basic-2: #383838;
$bg-basic-3: #f4f4f4;
