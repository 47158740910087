/*------------------------------------------------------------------
[18. Contact]
*/
.map-contact{
  .map-contact-canvas{
    height: 350px;
    @include breakpoint(md){
      height: 670px;
    }
  }
}
.box-content{
  padding: 15px;
  background-color: $clr-white;
  border-radius: 5px;
  margin-bottom: 20px;
  @include breakpoint(md){
    padding: 25px 30px;
    margin-bottom: 30px;
  }
  .box-content-text{
    color: $clr-2;
    line-height: 1.8;
    a{
      color: inherit;
      &:hover{
        color: $clr-1;
      }
    }
  }
  .box-content-title{
    font-size: 1.25em;
    font-weight: 600;
    margin-bottom: 7px;
    @include breakpoint(md){
      margin-bottom: 17px;
    }
    span{
      color: $clr-1;
    }
  }
}

