/*------------------------------------------------------------------
[10. Employer List]
*/
.filter-masonry {
  @include breakpoint(xl) {
    @include dFlex();
    @include flexWrap();
  }
  margin-bottom: 30px;
  .alpha {
    background-color: $clr-white;
    @include border-radius(5px);
    margin-bottom: 15px;
    padding: 0 20px;
    @include dFlex();
    @include flexWrap();
    @include breakpoint(xl) {
      @include flexWidth(calc(100% - 140px));
      margin-bottom: 0;
      @include justifyContent(space-between);
    }
  }
  .all {
    @include breakpoint(xl) {
      @include flexWidth(140px);
      padding-left: 10px;
    }
    .btn {
      min-width: 180px;
      @include breakpoint(xl) {
        display: block;
        width: 100%;
      }
    }
  }
  button.filter {
    font-weight: 600;
    color: $clr-2;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    &.is-checked {
      color: $clr-basic-text;
    }
  }
}
.job-item.employer {
	.col-xl-4 {
		@include breakpoint(xl) {
			@include flexWidth(204px);
		}
	}
	.col-xl-8 {
		@include breakpoint(xl) {
			@include flexWidth(calc(100% - 204px));
		}
	}
	.btn-light {
		@include breakpoint(xl) {
			padding-left: 10px;
			padding-right: 10px;
			display: block;
		}
	}
	.number-position {
		margin-top: 10px;
		@include breakpoint(xl) {
			margin-top: 0;
		}
	}
	&:hover {
		.btn-light {
			color: $clr-white;
			background-color: $clr-1;
			border-color: $clr-1;
		}
	}
}
.employer-item {
  .employer-item-inner {
    background-color: $clr-white;
    padding: 30px;
    @include border-radius(5px);
    margin-bottom: 30px;
  }
  h3 {
    font-size: 1.125em;
    font-weight: 600;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      a {
        color: $clr-2;
        &:hover, &:focus {
          color: $clr-1;
        }
      }
    }
  }
}

/*----------- Employer Detail -----------*/
.item-detail-special {
  background-color: $clr-white;
  @include border-radius(5px);
  margin-bottom: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @include breakpoint(md){
    padding: 33px 0;
  }
  .img, .text{
    position: relative;
    min-height: 1px;
  }
  .img {
    @include flexWidth(100%);
    text-align: center;
    @include breakpoint(md){
      @include flexWidth(160px);
    }
  }
  .text{
    @include flexWidth(100%);
    @include breakpoint(md){
      @include flexWidth(calc(100% - 160px));
    }
    .btn-feature{
      padding-right: 10px;
      @include breakpoint(lg){
        padding-right: 30px;
        text-align: right;
        margin-top: 0;
      }
    }
  }
  .btn-add-favourites {
    padding-left: 12px;
    padding-right: 12px;
  }
  .btn {
    margin-top: 4px;
    margin-bottom: 4px;
    &+.btn {
      margin-left: 4px;
    }
  }
}
.date-style2 {
  color: $clr-2;
  padding-bottom: 3px;
  span {
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
  i {
    margin-right: 4px;
  }
  .fa-check-circle {
    color: $clr-1;
  }
}
.title-detail {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 9px;
}

.title-block {
  font-weight: 600;
  font-size: 1.125em;
  margin-bottom: 24px;
}

.simillar-jobs {
  margin-bottom: 30px;
  .job-item {
    &:last-child {
      border-bottom: none;
    }
  }
}
.stars{
  display: inline-block;
  .point{
    display: inline-block;
    color: #ffb13c;
  }
}
.star-rating{
  color: #ffb13c;
  display: inline-block;
  i{
    font-size: 1.125em;
  }
}
.single-detail {
  color: $clr-2;
  margin-bottom: 27px;
  padding: 20px;
  @include breakpoint(md){
    padding: 30px;
  }
  h3 {
    font-size: 1.125em;
    color: $clr-basic-text;
    margin-bottom: 20px;
    font-weight: 600;
  }
  hr {
    border-top-color: #e4e4e4;
    margin: 25px -20px;
    @include breakpoint(md){
      margin-right: -30px;
      margin-left: -30px;
    }
  }
  p {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/*----------- review-wrap -----------*/
.reviews{
  background-color: $clr-white;
  border-radius: 5px;
  margin-bottom: 30px;
  .review-item{
    padding: 20px;
    @include breakpoint(md){
      padding: 30px;
    }
    &:not(:last-child){
      border-bottom: 1px solid #e4e4e4;
    }
    .review-content{
      color: $clr-2;
      margin-top: 16px;
    }
    .review-title{
      font-size: 1.125em;
      line-height: 26px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .meta-text{
      color: $clr-2;
    }
  }
}